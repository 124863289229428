@mixin vertical-rhythm($font-size, $line-height) {
  font-size: rem-calc($font-size);
  line-height: math.div($line-height, $font-size);
}

@mixin bottom-margin($size: 24) {
  margin: rem-calc(0 0 $size);
}

// adapteed from Foundation responsive-embed mixin
// $ratio: 'x by y'
@mixin banner-ratio($ratio) {
  position: relative;
  height: 0;
  padding-bottom: ratio-to-percentage($ratio);
  overflow: hidden;
  > * {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

// centred max-width
// $size: unitless
// $needs-padding: may be needed
@mixin max-width($size: 1536, $needs-padding: false, $padding: 24) {
  margin-left: auto;
  margin-right: auto;
  @if $needs-padding == true {
    padding-left: rem-calc($padding);
    padding-right: rem-calc($padding);
  }
  @if $needs-padding == true {
    max-width: rem-calc($size + ($padding * 2));
  } @else {
    max-width: rem-calc($size);
  }
}

@mixin menu-icon(
  $width1: 48,
  $width2: 82,
  $ratio: 0.744257086999022,
  $breakpoint: medium
) {
  width: rem-calc($width1);
  height: rem-calc($width1 * $ratio);
  @include breakpoint($breakpoint) {
    width: rem-calc($width2);
    height: rem-calc($width2 * $ratio);
  }
}

@mixin body-font {
  font-family: $body-font;
  font-style: normal;
  font-weight: normal;
}

@mixin page {
  @include max-width;
  padding: rem-calc(72 24 60);
  @include breakpoint(large) {
    padding-left: rem-calc(42);
    padding-right: rem-calc(42);
  }
}
@mixin logo($logo-ratio, $logo-height) {
  height: rem-calc($logo-height);
  width: rem-calc($logo-ratio * $logo-height);
  a,
  .svg-icon,
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}
