.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  // margin-left: rem-calc(-12);
  // margin-right: rem-calc(-12);
  > a {
    position: relative;
    padding-right: rem-calc(30);
    text-decoration: none;
    &::after {
      content: '';
      display: block;
      background-image: $icon-slash;
      height: rem-calc(14);
      width: rem-calc(14);
      position: absolute;
      right: rem-calc(8);
      top: 50%;
      transform: translateY(rem-calc(-6));
    }
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
  > a,
  > span {
    color: $dark-cornflower-blue;
    @include vertical-rhythm(14, 18);
    display: block;
  }
  > span {
    font-weight: bold;
  }
}