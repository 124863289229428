h1,
.h1,
h2,
.h2 {
  font-family: $heading-font;
  font-weight: 600;
  color: $dark-cornflower-blue;
}
h1,
.h1 {
  @include vertical-rhythm(44, 54);
  @include breakpoint(medium) {
    @include vertical-rhythm(64, 72);
  }
  margin: 0 0 rem-calc(24);
}
h2,
.h2,
.standard-page h1,
.standard-page .h1 {
  @include vertical-rhythm(44, 54);
  margin: 0 0 rem-calc(42);
}
h3,
.h3,
.standard-page h2,
.standard-page .h2 {
  @include vertical-rhythm(36, 42);
  margin: 0 0 rem-calc(24);
}
.standard-page h2,
.standard-page .h2 {
  font-family: $body-font;
  font-weight: bold;
  color: $body-text;
}
h4,
.h4,
.standard-page h3,
.standard-page .h3 {
  @include vertical-rhythm(24, 30);
  margin: 0 0 rem-calc(24);
}
h5,
.h5,
.standard-page h4,
.standard-page .h4 {
  @include vertical-rhythm(18, 24);
  margin: 0 0 rem-calc(18);
}
h6,
.h6,
.standard-page h5,
.standard-page .h5 {
  @include vertical-rhythm(16, 24);
  margin: 0 0 rem-calc(16);
}
.h3,
.h4,
.h5,
.h6 {
  font-weight: bold;
  color: $body-text;
  font-family: $body-font;
}
