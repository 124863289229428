.content-top {
  padding: rem-calc(0 0 60);
  // @include breakpoint(large) {
  //   padding-top: 0;
  // margin-bottom: rem-calc(60);
  // }

  // .sidebar-nav-wrapper {
  //   margin-top: rem-calc(36);
  //   .sidebar-toggle {
  //     width: 100%;
  //     .svg-icon {
  //       height: rem-calc(30);
  //       width: rem-calc(30);
  //       transform: rotate(0);
  //       transition: transform $medium-slow $easing;
  //     }
  //     &[aria-expanded='true'] {
  //       .svg-icon {
  //         transform: rotate(180deg);
  //       }
  //     }
  //     &[aria-expanded='false'] {
  //       .svg-icon {
  //         transform: rotate(0);
  //       }
  //     }
  //   }
  //   .sidebar-nav {
  //     display: none;
  //     background: $metallic-orange;
  //     padding: rem-calc(0 30 30);
  //     &.opening {
  //       animation: expand-down $medium-slow $easing;
  //     }
  //     &.closing {
  //       animation: contract-up $medium-slow $easing;
  //     }
  //     > li {
  //       @include vertical-rhythm(18, 24);
  //       font-weight: bold;
  //       &:first-child {
  //         padding-top: rem-calc(20);
  //         border-top: rem-calc(1) solid $body-text;
  //       }
  //       &:not(:first-child) {
  //         margin-top: rem-calc(24);
  //       }
  //       > a {
  //         color: $body-text;
  //         text-decoration: none;
  //         &.current {
  //           border-left: rem-calc(5) solid $body-text;
  //           padding-left: rem-calc(11);
  //         }
  //       }
  //     }
  //   }
  // }
}
