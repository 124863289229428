.utilities-nav {
  height: 100%;
  margin-left: rem-calc(60);
  > ul,
  li,
  a {
    height: 100%;
  }
  > ul {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: rem-calc(12);
    @include breakpoint(xlarge) {
      grid-template-columns: repeat(2, rem-calc(210));
      gap: rem-calc(36);
    }

    > li {
      @include vertical-rhythm(20, 24);
      position: relative;
      &:last-child {
        > a {
          .inner {
            border-bottom-color: $tiffany-blue;
          }
        }
        .subnav {
          border-top-color: $tiffany-blue;
        }
      }

      > a {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        text-decoration: none;
        border-width: rem-calc(6 0);
        border-style: solid;
        border-color: transparent;
        .inner {
          width: 100%;
          display: flex;
          // flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          gap: rem-calc(18);
          padding-bottom: rem-calc(10);
          border-bottom: rem-calc(2) solid $fresh-air;
          &::after {
            content: $icon-chevron-down-light-white;
            width: rem-calc(18);
            height: rem-calc(18);
            position: relative;
            top: rem-calc(2);
            line-height: 1;
            // transform: translateY(rem-calc(3));
            transform: rotate(0);
            transition: transform $medium-slow $easing;
          }
        }
        &:hover,
        &:active {
          color: $white;
          text-decoration: underline;
        }
        &.section {
          border-bottom-color: $metallic-orange;
        }

        &.opening {
          .inner::after {
            transform: rotate(180deg);
          }
        }
        &.closing {
          .inner::after {
            transform: rotate(0);
          }
        }
      }
    }
  }
  .drop-down {
    display: none;
    position: absolute;

    top: rem-calc(73);
    width: 100%;
    left: 0;
    .loaded & {
      transition: top $medium-slow $easing;
    }
    .fixed-header & {
      top: rem-calc(58);
    }
    @include breakpoint(xlarge) {
      width: rem-calc(258);
      left: rem-calc(-24);
    }
    &.opening {
      .subnav {
        animation: slide-down $medium-slow $easing;
      }
    }
    &.closing {
      .subnav {
        animation: slide-up $medium-slow $easing;
      }
    }
    .subnav {
      border-top: rem-calc(6) solid $fresh-air;
      background-color: $white;
      box-shadow: $black-box-shadow;
      > li {
        > a {
          display: block;
          padding: rem-calc(14 9 15);
          @include vertical-rhythm(18, 24);
          color: $catalina-blue;
          text-decoration: none;
          &:hover,
          &:active {
            background-color: $alice-blue;
            text-decoration: none;
          }
        }
        &:first-child {
          > a {
            padding-top: rem-calc(15);
          }
        }
        &:not(:last-child) {
          border-bottom: rem-calc(1) solid $beau-blue;
        }
      }
    }
  }
}
