.user-search {
  z-index: 2;
}
.utilities-nav {
  z-index: 1;
}
.user-search {
  &:hover {
    .dropdown-content {
      display: block;
      transition: all $medium-slow $easing;
      opacity: 1;
      animation: slide-down $fast $easing;
      animation-timing-function: linear;
    }
  }
  > a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include vertical-rhythm(20, 30);
    color: $dark-cornflower-blue;
    text-decoration: none;
    margin-left: 1rem;

    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
}
.user-search
.dropdown-content {
  display: none;
  opacity: 0;
  -webkit-transition: transform 1s ease-out;
  -moz-transition: transform 1s ease-out;
  -o-transition: transform 1s ease-out;
  // transition: transform $medium-slow $easing;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  top: rem-calc(30); // * 1.8 rem
  width: 100%;
  left: 50%;
  transform: scale(1);
  transition: all $medium-slow $easing;
  animation: slide-down $medium-slow $easing;
  animation-timing-function: linear;
  .loaded & {
    transition: all $medium-slow $easing;
  }
 .fixed-header & {
    top: rem-calc(30);
  }
  @include breakpoint(xlarge) {
    width: rem-calc(258);
    left: rem-calc(15); // * -24
  }
  &.opening {
    .subnav {
       animation: slide-down $medium-slow $easing;
     }
  }
  &.closing {
    .subnav {
      animation: slide-up $medium-slow $easing;
    }
  }
  .subnav {
    border-top: rem-calc(6) solid $fresh-air;
    background-color: $white;
    box-shadow: $black-box-shadow;
    // transition: transform $medium-slow $easing;
    -webkit-transition: opacity 1s ease-out;
    -moz-transition: opacity 1s ease-out;
    -o-transition: opacity 1s ease-out;
    transition: opacity 1s ease-out;
    > li {
      > a {
        display: block;
        padding: rem-calc(14 9 15);
        @include vertical-rhythm(18, 24);
        color: $catalina-blue;
        text-decoration: none;
        &:hover,
        &:active {

          background-color: $alice-blue;
          text-decoration: none;
        }
      }
      &:first-child {
        > a {
          padding-top: rem-calc(15);
        }
      }
      &:not(:last-child) {
        border-bottom: rem-calc(1) solid $beau-blue;
      }
      &.opening {
        ::after {
          transform: rotate(180deg);
        }
      }
      &.closing {
        ::after {
          transform: rotate(0);
        }
      }
    }
  }
}
.search-homepage-form
.search-inner
.select-wrapper {
  flex: 0 0 rem-calc(345);
}
