.search-results-form {
  display: flex;
  align-items: center;

  .search-inner {
    flex: 1 1 auto;
    border-bottom: rem-calc(6) solid $catalina-blue;
    padding: rem-calc(0 0 6 42);
    margin-right: rem-calc(24);
    position: relative;
    &::before {
      content: $icon-search-light-catalina-blue;
      height: rem-calc(30);
      width: rem-calc(30);
      position: absolute;
      left: 0;
      bottom: rem-calc(18);
    }
  }
}
.search-homepage-form {
  background-color: $white;
  .search-inner {
    display: flex;
    align-items: center;
    height: rem-calc(96);
    border: rem-calc(2) solid $grey;
    box-shadow: $black-box-shadow;
    padding: rem-calc(0 28);
    &:focus-within {
      border-color: $catalina-blue;
    }
    .form-item {
      margin-bottom: 0;
    }
    .form-text-wrapper {
      flex: 1 1 auto;
    }
    .search-input {
      padding: rem-calc(0 60 0 0);
      border-right: rem-calc(1) solid $dark-cornflower-blue;
      @include vertical-rhythm(24, 36);
      height: rem-calc(36);
    }
    .select-wrapper {
      flex: 0 0 rem-calc(210);
      .select-inner {
        background-color: transparent;
        border: none;
        color: $catalina-blue;
        height: rem-calc(36);
        .edit-select {
          color: $catalina-blue;
          @include vertical-rhythm(20, 36);
          height: 100%;
        }
        &::after {
          content: $icon-chevron-down-dark-cornflower-blue;
          height: rem-calc(18);
          width: rem-calc(18);
          // bottom: rem-calc(15);
          // transform: translateY(0);
        }
      }
    }
    .form-action {
      flex: 0 0 rem-calc(36);
    }
    .search-button {
      height: rem-calc(36);
      width: rem-calc(36);
      &::before {
        content: $icon-search-light-metallic-orange;
        height: 100%;
        width: 100%;
      }
    }
  }
}
