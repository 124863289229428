.banner {
  $lg-bp: 1774;
  // coordinates when SVG changes its positioning parent from .container to .banner
  height: rem-calc(330);
  position: relative;
  overflow-x: hidden;
  &.banner-01 {
    background-image: linear-gradient(
      to right,
      $catalina-blue 0%,
      $dark-cornflower-blue 100%
    );
  }
  &.banner-02 {
    background-image: linear-gradient(
      to right,
      $dark-cornflower-blue 0%,
      $green-blue 100%
    );
  }
  &.banner-03 {
    background-image: linear-gradient(
      to right,
      $green-blue 0%,
      $dark-cornflower-blue 143%
    );
  }
  &.banner-04 {
    background-image: linear-gradient(
      to right,
      $fresh-air -22%,
      $green-blue 57%
    );
    .svg-icon {
      color: $alice-blue;
    }
    h1,
    .summary {
      text-shadow: $text-shadow;
    }
  }
  h1,
  .summary {
    color: $white;
  }
  .image {
    // @include logo($banner-ratio, 330);
    // color: $fresh-air;
    height: 100%;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: rem-calc(738);
    // @include breakpoint(xlarge) {
    //   left: rem-calc(826);
    // }
    @include breakpoint($lg-bp) {
      left: auto;
      right: 0;
    }
  }
  .container,
  .content {
    height: 100%;
  }
  .container {
    position: relative;
    @include breakpoint($lg-bp) {
      position: static;
    }
  }

  .content {
    max-width: rem-calc(708);
    display: flex;
    flex-direction: column;
    justify-content: center;
    // @include breakpoint(xlarge) {
    //   margin-left: rem-calc(88);
    // }
    h1 {
      max-width: rem-calc(618);
    }
    .summary {
      @include vertical-rhythm(20, 30);
    }
  }
}
