.login-box {
  width: rem-calc(768);
  padding: rem-calc(60 84);
  background-color: $white;
  box-shadow: $black-box-shadow;
  h1 {
    margin-bottom: rem-calc(30);
  }
  .forgot-password {
    margin-top: rem-calc(30);
    padding-top: rem-calc(29);
    border-top: rem-calc(1) solid $body-text;
    a {
      @include vertical-rhythm(20, 24);
      text-decoration: none;
    }
  }
}
.one-column-page-block {
  .inner {
    @include max-width(1216, true);
    padding-bottom: rem-calc(30);
    position: relative;
    .bg-images {
      > div {
        position: absolute;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.search-form-block {
  background-color: $anti-flash-white;
  &:focus-within {
    background-color: $alice-blue;
  }
  padding-top: rem-calc(42);
  padding-bottom: rem-calc(42);
  .results-summary {
    margin: rem-calc(30 0 0);
  }
}
