.container {
    @include max-width(1600, true, 15);
    min-width: rem-calc(100);

    @include breakpoint(large) {
        padding-left: 30px;
        padding-right: 30px;
    }
}

main,
footer {
    position: relative;
}

main {
    margin-top: rem-calc($header-h);
    z-index: 2;

    .loaded & {
        transition: margin-top $medium-slow $easing;
    }

    .half-header & {
        margin-top: rem-calc($header-top-h);

        .fixed-header & {
            margin-top: rem-calc($header-top-h-smaller);
        }
    }

    .fixed-header & {
        margin-top: rem-calc($header-h-smaller);
    }

    &.light-bg {
        background-color: $alice-blue;
    }

    > .container {
        &.one-col,
        &.two-col {
            padding-top: rem-calc(66);
        }

        &.two-col {
            display: flex;

            .sidebar {
                flex: 1 1 rem-calc(180);
                margin-right: rem-calc(24);
                max-width: rem-calc(240);

                @include breakpoint(xlarge) {
                    flex-basis: rem-calc(240);
                    max-width: rem-calc(300);
                }
            }

            .content {
                flex: 1 1 rem-calc(900);
                min-width: 400px;
            }
        }
    }
}

footer {
    z-index: 1;
}

body > .full-height {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    > main {
        flex-grow: 1;
    }
}

.grid {
    display: grid;
}

.links {
    display: flex;
    gap: rem-calc(30);
}
