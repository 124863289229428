.feature-page,
.pricing-page {
  .sprite-06-svg {
    width: rem-calc(426);
    height: rem-calc(718);
    right: rem-calc(-418);
  }
}
.pricing-page {
  .sprite-05-svg {
    width: rem-calc(318);
    height: rem-calc(318);
    bottom: rem-calc(74);
    left: rem-calc(-302);
  }
  .sprite-06-svg {
    top: rem-calc(29);
  }
}
.feature-page {
  .sprite-06-svg {
    top: rem-calc(-256);
  }
}
.pricing-blocks {
  margin-top: rem-calc(30);
  .grid {
    padding: rem-calc(0 36);
    gap: rem-calc(36);
    grid-template-columns: repeat(
      auto-fill,
      minmax(min(100%, rem-calc(326)), 1fr)
    );
  }
}
