.footer-nav {
  ul > li {
    @include vertical-rhythm(20, 30);
  }
  a {
    display: inline-block;
    color: $white;
    text-decoration: none;
    &:hover,
    &:active {
        color: $white;
        text-decoration: underline;
    }
  }
}
