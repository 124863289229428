// logo
.logo {
    .logo-svg {
        @include logo($logo-ratio, 66);

        .fixed-header & {
            @include logo($logo-ratio, 33);
        }
    }

    .on-hover {
        display: none;
    }

    > a {
        display: block;

        &:hover,
        &:active {
            .on-hover {
                display: block;
            }

            .on-load {
                display: none;
            }
        }
    }
}

.image {
    img {
        max-width: 100%;
        height: 100%;
        display: block;
    }

    &.raster {
        img {
            width: 100%;
            height: auto;
        }
    }
}

.builders-svg {
    img {
        aspect-ratio: 512/424;
    }
}

.management-svg {
    img {
        aspect-ratio: 354/344;
    }
}

.renovators-svg {
    img {
        aspect-ratio: 426/420;
    }
}

.building-svg {
    img {
        aspect-ratio: 100/100;
    }
}

.calculator-svg {
    img {
        aspect-ratio: 72.2/100;
    }
}

.clipboard-svg {
    img {
        aspect-ratio: 72.2/100;
    }
}

.set-square-svg {
    img {
        aspect-ratio: 100/100;
    }
}

.landing-page-v1-svg,
.landing-page-v2-svg {
    img {
        aspect-ratio: 880/330;
    }
}

.landing-page-v3-svg {
    img {
        aspect-ratio: 958/646;
    }
}

.qv-logo-svg {
    @include logo($qv-logo-ratio, 54);

    img {
        aspect-ratio: 100/84.5;
    }
}

.monitor-img {
    img {
        aspect-ratio: 360.495/285.688;
    }
}

.monitor-svg {
    img {
        aspect-ratio: 360.849/340.884;
    }
}
