.mobile-nav {
  .subnav {
    $ht: 62;
    background-color: $white;
    z-index: 1;
    .header {
      .inner {
        height: rem-calc($ht);
        .actions {
          justify-content: space-between;
          // align-items: flex-end;
        }
        .section-title {
          @include vertical-rhythm(30, 40);
          font-weight: bold;
          color: $black;
        }
      }
    }
    .body {
      margin-top: rem-calc($ht);
      height: calc(100vh - #{rem-calc($ht)});
    }
  }
  ul {
    border-top: rem-calc(1) solid $beau-blue;
    > li > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      @include vertical-rhythm(24, 30);
      padding: rem-calc(29 0 30);
      border-bottom: rem-calc(1) solid $beau-blue;
      .svg-icon {
        height: rem-calc(30);
        width: rem-calc(30);
      }
    }
  }
}

