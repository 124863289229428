.skiplink {
  display: inline-block;
  position: absolute;
  z-index: 4;
  top: rem-calc(12);
  left: rem-calc(12);
  transform: translateX(-200%);
  &:focus {
    transform: translateX(0);
  }
}
[id='content'] {
  scroll-margin-top: rem-calc(
    300
  ); //may not not work in Older versions of Safari or iOS
}
