.primary-nav {
  height: 100%;
  ul,
  li,
  a {
    height: 100%;
  }
  ul {
    display: grid;
    grid-template-columns: repeat(5, auto);
    gap: rem-calc(20);
    @include breakpoint(xlarge) {
      gap: rem-calc(40);
    }

    > li {
      @include vertical-rhythm(20, 24);
    }
  }
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $white;
    text-decoration: none;
    border-width: rem-calc(6 0);
    border-style: solid;
    border-color: transparent;
    &:hover,
    &:active {
      color: $white;
      text-decoration: underline;
    }
    &.section {
      border-bottom-color: $metallic-orange;
      font-weight: bold;
    }
  }
}
