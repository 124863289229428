.table-holder {
  overflow-x: auto;
}
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: rem-calc(30);
  tbody:first-child > tr:first-child {
    th,
    td {
      border-top: rem-calc(1) solid $wenge;
    }
  }
  thead th,
  tbody td {
    @include vertical-rhythm(18, 24);
  }
  thead th {
    background-color: transparent;
    text-align: left;
    font-weight: bold;
    padding-bottom: rem-calc(12);
    border-bottom: rem-calc(2) solid $wenge;
  }
  tbody {
    td {
      padding-top: rem-calc(12);
      padding-bottom: rem-calc(12);
      border-bottom: rem-calc(1) solid $wenge;
    }
    tr:first-child {
      td {
        padding-top: rem-calc(12);
      }
    }
  }
  &.text-table {
    thead {
      th {
        padding: rem-calc(12 8 12);
      }
    }
    tbody {
      td {
        padding: rem-calc(12 8 12);
      }
    }
  }
  &.data-table {
    min-width: rem-calc(1012);

    thead {
      th,
      td:not(:empty) {
        padding: rem-calc(9 12);
        @include vertical-rhythm(16, 24);
        vertical-align: middle;
        text-align: center;
        border-left: rem-calc(1) solid $white;
        border-bottom: none;
        background-color: $gainsboro;
        font-weight: normal;
        &:first-child {
          border-left: none;
        }
      }
      tr.header-header {
        th,
        td:not(:empty) {
          background-color: $anti-flash-white;
        }
      }
    }
    tbody {
      // .heading {
      //   th {
      //     background-color: $green-blue;
      //     color: $white;
      //     @include vertical-rhythm(24, 30);
      //     padding: rem-calc(12);
      //     text-align: left;
      //   }
      // }

      td,
      th {
        padding: rem-calc(12 18);
        @include vertical-rhythm(14, 18);
        border-bottom-color: $gainsboro;
        vertical-align: middle;
        text-align: right;
        &:first-child {
          text-align: left;
        }
      }
      th {
        font-weight: normal;
      }
      tr {
        &.strong,
        &.subtotal,
        &.total {
          td {
            font-weight: bold;
          }
        }
        &.subtotal {
          td {
            background-color: $bright-grey;
          }
        }
        &.total {
          td {
            background-color: $alice-blue;
            &:first-child {
              @include vertical-rhythm(18, 24);
            }
          }
        }
        &.no-border {
          td {
            border-bottom: none;
          }
        }
      }
      // tr:last-child {
      //   td {
      //     border-bottom: none;
      //   }
      // }
    }
    .item {
      font-weight: bold;
      display: block;
    }
  }
}
caption {
  @include vertical-rhythm(24, 30);
  margin: 0 0 rem-calc(24);
  font-weight: bold;
  text-align: left;
}
.htLeft {
  text-align: left !important;
}
.htCenter {
  text-align: center !important;
}
.htRight {
  text-align: right !important;
}
.htJustify {
  text-align: justify !important;
}
.htTop {
  vertical-align: top !important;
}
.htMiddle {
  vertical-align: middle !important;
}
.htBottom {
  vertical-align: bottom !important;
}
