.hgroup {
  .subsection-heading {
    margin-bottom: rem-calc(36);
  }
  .page-title {
    margin-bottom: rem-calc(30);
  }
}

.results-summary {
  @include vertical-rhythm(20, 30);
  font-weight: bold;
  margin-bottom: rem-calc(42);
}
