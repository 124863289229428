fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
legend,
.fake-legend {
  font-weight: bold;
  @include vertical-rhythm(36, 48);
  margin: 0 0 rem-calc(20);
}

.form-item {
  position: relative;
  z-index: 1;
  margin-bottom: rem-calc(20);
}
.form-items {
  // 2 side-by-side
  margin-bottom: rem-calc(20);
  display: grid;
  grid-gap: rem-calc(30 12);
  @include breakpoint(xmedium) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-gap: rem-calc(0 12);
  }
  @include breakpoint(medium) {
    grid-gap: rem-calc(0 24);
  }
  .form-item,
  .control-group {
    margin-bottom: 0;
    @include breakpoint(xmedium) {
      display: contents;
      &:first-child {
        > * {
          grid-column: 1/2;
        }
      }
      &:last-child {
        > * {
          grid-column: 2/3;
        }
      }
      :first-child {
        grid-row: 1/2;
      }
      :nth-child(2) {
        grid-row: 2/3;
      }
      :nth-child(3) {
        grid-row: 3/4;
      }
    }
  }
  .form-radios &,
  .form-checkboxes & {
    display: flex;
    align-items: flex-end;
    // margin-bottom: rem-calc(32);
    // @include breakpoint(medium) {
    //   margin-bottom: rem-calc(48);
    // }
    margin-left: rem-calc(-6);
    margin-right: rem-calc(-6);
    @include breakpoint(medium) {
      margin-left: rem-calc(-12);
      margin-right: rem-calc(-12);
    }
    .form-item,
    .control-group {
      width: 50%;
      margin-bottom: 0;
      padding-left: rem-calc(6);
      padding-right: rem-calc(6);
      @include breakpoint(xmedium) {
        display: block;
        label {
          margin-bottom: revert;
        }
        .description {
          margin-top: revert;
        }
      }
      @include breakpoint(medium) {
        padding-left: rem-calc(12);
        padding-right: rem-calc(12);
      }
    }
  }
}

label,
.fake-label {
  display: block;
  color: $wenge;
  @include vertical-rhythm(20, 24);
  margin-bottom: rem-calc(16);
  abbr[title] {
    text-decoration: none;
  }
}
.description {
  @include vertical-rhythm(16, 24);
  color: $wenge;
  margin-top: rem-calc(6);
}
.standard-input {
  background-color: $input-bg;
  border: rem-calc(1) solid $input-border;
  color: $body-text;
  width: 100%;
  padding: rem-calc(0 17);
  @include vertical-rhythm(18, 24);
  height: rem-calc(54);
  display: inline-flex;
  align-items: center;
  transition: border-color $slow $easing;
  &.form-textarea {
    display: block;
    height: auto;
    padding: rem-calc(17);
  }
  &:focus {
    border-color: $input-border-foc;
  }

  &:focus,
  &:active {
    outline: 0;
  }
  &:-moz-focusring {
    outline: 0;
  }
  &.error {
    border-color: $input-err;
  }
  &:invalid {
    // suppress the native HTML5 error style
    box-shadow: none;
  }
  &[type='number'] {
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  &[type='file'] {
    /* remove the control from the page flow, positioning it on top of the label */
    position: absolute;
    /* set same dimensions as the label */
    width: 100%;
    height: rem-calc(54);
    /* hide it */
    opacity: 0.00001;
  }
}
.search-input {
  background-color: transparent;
  border: none;
  padding: rem-calc(0 18);
  @include vertical-rhythm(40, 48);
  font-weight: 300;
}
.has-clear-icon {
  position: relative;
  .search-input + .clear-text {
    position: absolute;
    height: rem-calc(24);
    width: rem-calc(24);
    right: rem-calc(18);
    top: 50%;
    transform: translateY(-50%);
    transition: none;
    &::before {
      content: $icon-text-clear-wenge;
      transform: translateY(0);
    }
  }
}
.form-file-wrapper {
  position: relative;
  label {
    background-color: $white;
    border: rem-calc(1) solid $input-border;
    border-radius: rem-calc(4);
    color: $black;
    width: 100%;
    padding: rem-calc(0 17);
    @include vertical-rhythm(16, 24);
    height: rem-calc(56);
    display: inline-flex;
    align-items: center;
    margin-bottom: 0;
  }
}
.select-wrapper {
  &.form-item--error {
    > div {
      border-color: $input-err;
    }
  }
  .select-inner {
    background-color: $input-bg;
    border: rem-calc(1) solid $input-border;
    color: $body-text;
    position: relative;
    transition: border-color $slow $easing;
    &:focus-within {
      border-color: $input-border-foc;
    }

    &::after {
      content: $icon-chevron-down-light-wenge;
      position: absolute;
      right: rem-calc(21);
      bottom: 50%;
      transform: translateY(50%);
      pointer-events: none;
      z-index: 1;
      height: rem-calc(24);
      width: rem-calc(24);
    }
  }
  [class*='edit-'] {
    appearance: none;
    background: transparent;
    height: rem-calc(52);
    border: none;
    &::-ms-expand {
      display: none;
    }
    &:focus {
      border: none;
    }
    &:focus,
    &:active {
      outline: 0;
      background: transparent;
    }
    &:-moz-focus-ring {
      outline: 0;
    }
    &:-moz-focus-inner {
      border: 0;
    }
    option {
      &:focus,
      &:active {
        outline: 0;
        background: transparent;
      }
    }
  }
}
.select-wrapper {
  [class*='edit-'] {
    padding-right: rem-calc(66);
  }
  .svg-icon {
    height: rem-calc(18);
    width: rem-calc(18);
    right: rem-calc(24);
  }
}

:disabled {
  cursor: not-allowed;
  opacity: 0.5;
  // background-color: $disabled-bg;
  // color: $disabled-fg;
  // border-color: $disabled-bg;
}
.disabled {
  opacity: 0.5;
  // label,
  // .svg-icon {
  //   color: $disabled-fg;
  // }
}
.form-item--error-message {
  @include vertical-rhythm(14, 24);
  margin-top: rem-calc(18);
  color: $input-err;
  font-weight: bold;
  display: flex;
  // align-items: flex-start;
  &::before {
    content: $icon-warning;
    width: rem-calc(20);
    height: rem-calc(20);
    margin-right: rem-calc(12);
    transform: translateY(rem-calc(3));
  }
}
.form-item--success-message {
  @include vertical-rhythm(14, 24);
  margin-top: rem-calc(18);
  color: $input-success;
  font-weight: bold;
  display: flex;
  &::before {
    content: $icon-tick;
    width: rem-calc(20);
    height: rem-calc(20);
    margin-right: rem-calc(12);
    transform: translateY(rem-calc(3));
  }
}
// Checkboxes and radio buttons
[type='checkbox'],
[type='radio'] {
  /* remove the checkbox from the page flow, positioning it on top of the SVG */
  position: absolute;
  /* set same dimensions as the SVG */
  width: rem-calc(24);
  height: rem-calc(24);
  /* hide it */
  opacity: 0.00001;
}
.form-checkboxes,
.form-radios {
  //margin-bottom: rem-calc(20);
  > .inner {
    margin-top: rem-calc(-12);
  }
  .form-item {
    margin-bottom: 0;
    padding: rem-calc(12 0 12 12);
  }
  .form-items {
    margin-bottom: 0;
    .form-item {
      margin-bottom: 0;
    }
  }

  .form-checkbox,
  .form-radio {
    padding: 0;
    p {
      line-height: inherit;
      font-size: inherit;
    }
    label {
      margin-bottom: 0;
      position: relative;
      font-weight: normal;
      @include vertical-rhythm(20, 24);
      padding: rem-calc(12 0);
      display: flex;
      align-items: flex-start;
      cursor: pointer;
      span {
        flex: 1 1 auto;
        padding-left: rem-calc(20);
      }

      svg {
        flex: 0 0 rem-calc(24);
        width: rem-calc(24);
        height: rem-calc(24);
        .checkbox-bg {
          fill: transparent;
          stroke: $body-text;
        }
        .checkbox-checkmark {
          stroke: transparent;
        }
        .radio-border {
          stroke: $body-text;
        }
        .radio-onoff {
          fill: transparent;
        }
        /* apply a transition to the elements inside the svg */
        * {
          transition: all 0.3s ease-in-out;
        }
        // .checkbox-bg {
        //   fill: $white;
        //   stroke: $darkish-grey;
        // }
      }
    }
    [type='checkbox']:checked + svg {
      .checkbox-bg {
        fill: $metallic-orange;
        stroke: $metallic-orange;
      }
      .checkbox-checkmark {
        stroke: $white;
      }
    }
    [type='radio']:checked + svg {
      .radio-border {
        stroke: $metallic-orange;
      }
      .radio-onoff {
        fill: $metallic-orange;
      }
    }
  }
}

[type='checkbox'],
[type='radio'] {
  &:disabled {
    & ~ svg,
    & ~ span {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}
// [type='radio'] {
//   @include element-invisible;
//   &:disabled + label {
//     opacity: 0.3;
//     cursor: not-allowed;
//   }
//   &:focus + label {
//     outline-offset: 1px;
//     outline: royalblue auto 1px !important;
//   }
// }

// .button-lock {
//   /* stops buttons lighting up as elements scroll underneath cursor */
//   .decision-button {
//     label:hover {
//       color: $red;
//       background-color: $white;
//     }
//   }
// }
// .decision-button {
//   label {
//     border: rem-calc(1) solid $red;
//     border-radius: rem-calc(8);
//     padding: rem-calc(15 56 15 23);
//     @include breakpoint(xmedium) {
//       padding: rem-calc(31 67 31 31);
//     }
//     margin: 0;
//     color: $red;
//     @include vertical-rhythm(18, 24);
//     cursor: pointer;
//     position: relative;
//     transition: all $fast $easing;
//     &:hover,
//     &:active {
//       background-color: $red;
//       color: $white;
//     }
//     .svg-icon-checked {
//       display: none;
//       height: rem-calc(36);
//       width: rem-calc(36);
//       color: $white;
//       position: absolute;
//       top: rem-calc(-14);
//       right: rem-calc(-14);
//       svg {
//         fill: $red;
//       }
//     }
//     .svg-icon-arrow-forward {
//       height: rem-calc(16);
//       width: rem-calc(16);
//       position: absolute;
//       right: rem-calc(32);
//       top: 50%;
//       transform: translateY(-50%);
//     }
//   }
//   [type='radio'] {
//     &:checked + label {
//       border-width: rem-calc(2);
//       padding: rem-calc(14 55 14 22);
//       @include breakpoint(xmedium) {
//         padding: rem-calc(30 66 30 30);
//       }
//       .svg-icon-checked {
//         display: block;
//       }
//     }
//   }
// }
// .nocaptcha {
//   margin: rem-calc(0 0 30);
// }
// .box-radios {
//   margin-bottom: rem-calc(32);
//   &:last-child {
//     margin-bottom: 0;
//   }
//   @include breakpoint(medium) {
//     margin-bottom: rem-calc(48);
//     &:last-child {
//       margin-bottom: 0;
//     }
//   }
//   .inner {
//     display: inline-flex;
//   }
//   .form-item,
//   label {
//     margin: 0;
//   }
//   label {
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     height: rem-calc(56);
//     @include vertical-rhythm(18, 24);
//     padding: rem-calc(0 23);
//     width: auto;
//     background-color: $white;
//     color: $black;
//     transition: all $fast $easing;
//     // border-radius: rem-calc(4);
//     cursor: pointer;
//     border-style: solid;
//     border-color: $darkish-grey;
//     &:hover,
//     &:active {
//       background-color: $red;
//       border-color: $red;
//       color: $white;
//     }
//   }
//   [type='radio'] {
//     &:checked + label {
//       background-color: $red;
//       border-color: $red;
//       color: $white;
//     }
//   }
//   .box-radio {
//     label {
//       border-width: rem-calc(1 1 1 0);
//     }
//     &:first-child {
//       label {
//         border-left-width: rem-calc(1);
//         border-radius: rem-calc(4 0 0 4);
//       }
//     }
//     &:last-child {
//       label {
//         border-radius: rem-calc(0 4 4 0);
//       }
//     }
//   }
// }
// .image-radios {
//   .image-radio {
//     margin-bottom: 0;
//     label {
//       cursor: pointer;
//       position: relative;
//       margin-bottom: 0;
//       * {
//         transition: all $fast $easing;
//       }
//       .image {
//         @include banner-ratio(16 by 9);
//         outline: 0;
//         object-fit: cover;
//         img {
//           border-radius: rem-calc(4);
//         }
//       }
//       .svg-icon {
//         height: rem-calc(26);
//         width: rem-calc(26);
//         position: absolute;
//         top: rem-calc(4);
//         right: rem-calc(4);
//       }
//       .text {
//         display: block;
//         font-weight: normal;
//         @include vertical-rhythm(16, 24);
//         margin-top: rem-calc(16);
//       }
//       [type='radio']:checked {
//         ~ .image {
//           outline: rem-calc(3) solid $red;
//           outline-offset: rem-calc(3);
//           border-radius: rem-calc(4);
//         }
//         ~ .svg-icon {
//           color: $white;
//           svg {
//             fill: $red;
//           }
//         }
//       }
//       &:hover {
//         .image {
//           outline: rem-calc(3) solid $red;
//           outline-offset: rem-calc(3);
//           border-radius: rem-calc(4);
//         }
//         .svg-icon {
//           color: $white;
//           svg {
//             fill: $red;
//           }
//         }
//       }
//     }
//   }
// }
.form-action {
  display: flex;
  gap: rem-calc(30);
}
