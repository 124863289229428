.pagination-container {
  margin-top: rem-calc(42);
  padding-bottom: rem-calc(12);
  .pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    // margin: rem-calc(0 -4);
    // > li {
    //   padding: rem-calc(0 4);
    // }
    a,
    .active > span,
    .ellipsis > span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: rem-calc(48);
      width: rem-calc(48);
      @include vertical-rhythm(24, 30);
      font-weight: bold;
    }
    .active > span {
      color: $white;
      background-color: $catalina-blue;
      position: relative;
      &::after {
        content: '';
        width: 100%;
        height: rem-calc(6);
        background-color: $metallic-orange;
        position: absolute;
        bottom: rem-calc(-12);
        left: 0;
      }
    }
    a {
      color: $catalina-blue;
      text-decoration: none;
      &:hover,
      &:active {
        text-decoration: underline;
      }
      &.prev,
      &.next {
        &::before {
          height: rem-calc(18);
          width: rem-calc(18);
          display: flex;
          transform: translateY(rem-calc(1));
        }
      }
      &.prev {
        &::before {
          content: $icon-chevron-left-catalina-blue;
        }
      }
      &.next {
        &::before {
          content: $icon-chevron-right-catalina-blue;
        }
      }
    }
  }
}
