.link-to-top {
  margin-top: rem-calc(60);
  margin-bottom: rem-calc(36);
  display: flex;
  justify-content: flex-end;
  a {
    color: $dark-cornflower-blue;
    text-decoration: none;
    @include vertical-rhythm(18, 30);
    font-weight: bold;
    > span {
      display: block;
    }
    &:hover,
    &:active {
      color: $metallic-orange;
      .box {
        border-color: $metallic-orange;
        &::before {
          content: $icon-arrow-up-metallic-orange;
        }
      }
    }
  }
  .box {
    border: rem-calc(2) solid $dark-cornflower-blue;
    width: rem-calc(30);
    height: rem-calc(30);
    display: block;
    position: relative;

    &::before {
      content: $icon-arrow-up-dark-cornflower-blue;
      width: rem-calc(12);
      height: rem-calc(12);
      display: block;
      position: absolute;
      left: 50%;
      transform: translate(-50%, rem-calc(-2));
    }
  }
}
