footer {
  background: $gradient-blue;
  padding: rem-calc(60 0 30);
  color: $white;
  .container {
    display: grid;
    gap: rem-calc(24);
    // @include breakpoint(xmedium) {
    //   grid-template-columns: 1fr 2fr;
    .logo,
    .copyright {
      grid-column: 1 / 3;
    }
    .footer-nav {
      grid-column: 1 / 2;
    }
    .contact {
      grid-column: 2 / 3;
    }
    // }
    // @include breakpoint(large) {
    grid-template-columns: 1fr 3fr;
    // }
    @include breakpoint(1260) {
      gap: rem-calc(12 0);
      grid-template-columns: rem-calc(336) 1fr rem-calc(210) 1fr rem-calc(564);
      .logo,
      .copyright {
        grid-column: 1 / 2;
      }
      .footer-nav {
        grid-column: 3 / 4;
      }
      .contact {
        grid-column: 5 / 6;
      }
    }
  }
  .contact {
    h2 {
      color: $white;
      font-family: $body-font;
      @include vertical-rhythm(24, 30);
      margin-bottom: rem-calc(24);
    }
    p {
      @include vertical-rhythm(18, 24);
      margin-bottom: rem-calc(24);
    }
    a {
      color: $white;
      text-decoration: none;
      &:hover,
      &:active {
        color: $white;
        text-decoration: underline;
      }
    }
    .qv {
      display: flex;
      align-items: center;
      margin-bottom: rem-calc(30);
      @include vertical-rhythm(18, 24);
      > a {
        margin-right: rem-calc(24);
      }
      p:last-child {
        margin-bottom: 0;
      }
    }
    .social-media {
      display: flex;
      > li {
        &:not(:last-child) {
          margin-right: rem-calc(16);
        }
        > a {
          display: block;
          @include vertical-rhythm(24, 24);
          &::before {
            display: block;
            width: rem-calc(24);
            height: rem-calc(24);
          }
          &.facebook {
            &::before {
              content: $icon-facebook-square-brands-white;
            }
            &:hover,
            &:active {
              &::before {
                content: $icon-facebook-square-brands-metallic-orange;
              }
            }
          }
          &.linkedin {
            &::before {
              content: $icon-linkedin-brands-white;
            }
            &:hover,
            &:active {
              &::before {
                content: $icon-linkedin-brands-metallic-orange;
              }
            }
          }
        }
      }
    }
  }
  .copyright {
    @include vertical-rhythm(12, 18);
    font-weight: 600;
  }
}
