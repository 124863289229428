.hyperlink {
  display: flex;
  align-items: center;
  @include vertical-rhythm(20, 30);
  text-decoration: none;
  font-weight: bold;
  &::after,
  &::before {
    content: $icon-arrow-right-catalina-blue;
    height: rem-calc(24);
    width: rem-calc(24);
  }
  &::after {
    margin-left: rem-calc(24);
  }
  &::before {
    display: none;
    margin-right: rem-calc(24);
  }
  &.icon-left {
    &::before {
      display: block;
    }
    &::after {
      display: none;
    }
  }
  &.download {
    &::after,
    &::before {
      content: $icon-download-dark-cornflower-blue;
    }
  }
  &:hover,
  &:active {
    &::after,
    &::before {
      content: $icon-arrow-right-metallic-orange;
    }
    &.download {
      &::after,
      &::before {
        content: $icon-download-metallic-orange;
      }
    }
  }
}
