.svg-icon {
  line-height: 1;
  svg {
    line-height: 1;
    width: 100%;
    height: 100%;
    fill: $white;
    color: inherit;
  }
}
span.svg-icon {
  display: inline-block;
  // position: relative;
  // svg {
  //     bottom: -0.15em;
  //     position: absolute;
  // }
}
// .loader {
//   height: rem-calc(72);
//   width: rem-calc(72);
//   @include breakpoint(medium) {
//     height: rem-calc(114);
//     width: rem-calc(114);
//   }
//   img {
//     height: 100%;
//     width: 100%;
//     animation: loader $slow infinite linear;
//   }
// }

// // Wrapper around the loading animation.
// .fullscreen-loader {
//   position: fixed;
//   z-index: 1000;
//   top: 50%;
//   left: 50%;
//   margin-top: rem-calc(-72);
//   margin-left: rem-calc(-72);
//   height: rem-calc(72);
//   width: rem-calc(72);
// }
