header {
    box-shadow: $box-shadow;
    background-color: $white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;

    .show-desktop {
        > div > .container {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .header-top {
            height: rem-calc($header-top-h);
            //padding-top: rem-calc(15);
            //padding-bottom: rem-calc(15);

            .loaded & {
                transition: height $medium-slow $easing;
            }

            .fixed-header & {
                height: rem-calc($header-top-h-smaller);
            }

            .logo {
                .loaded & {
                    transition: transform $medium-slow $easing;
                }

                //.fixed-header & {
                //    transform: scale(0.5);
                //}
            }
        }

        .header-bottom {
            height: rem-calc($header-bottom-h);

            .loaded & {
                transition: height $medium-slow $easing;
            }

            .fixed-header & {
                height: rem-calc($header-bottom-h-smaller);
            }

            background: $gradient-blue;
        }
    }
}
