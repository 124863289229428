.sitewide-alert {
    position: relative;
    z-index: 2;
    background-color: $caramel;

    .container {
        min-height: rem-calc(102);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .text {
        @include vertical-rhythm(24, 30);
        padding-top: rem-calc(10);
        padding-bottom: rem-calc(10);

        p:not(:last-child) {
            margin-bottom: 0;
        }
        ul {
            margin-left: 0;
            li {
                margin-bottom: 0;
                &:before {
                    background-color: $metallic-orange;
                }
            }
        }
    }
}
