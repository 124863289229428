.sidebar {
  .sidebar-header {
    padding-bottom: rem-calc(24);
    position: relative;
    .title {
      @include vertical-rhythm(24, 30);
      font-weight: bold;
    }
    &::after {
      content: '';
      width: 100%;
      height: rem-calc(1);
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: $catalina-blue;
      .landing-page & {
        height: rem-calc(2);
      }
    }
  }
  .sidebar-nav {
    padding-top: rem-calc(12);
    li {
      padding: rem-calc(3 0);
    }
    a {
      display: block;
      padding: rem-calc(12 0);
      text-decoration: none;
      color: $dark-cornflower-blue;
      @include vertical-rhythm(20, 24);
      &.current {
        color: $black;
        background-color: $gainsboro;
        font-weight: bold;
        border-left: rem-calc(12) solid $metallic-orange;
        padding-left: rem-calc(12);
      }
      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }
}
