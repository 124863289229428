body,
html {
  font-size: 100%;
}
body {
  font-family: $body-font;
  color: $body-text;
  background-color: $body-bg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .js-hide & {
    visibility: hidden;
  }
  &.loaded {
    transition: all $fast $easing;
  }
  @include vertical-rhythm(16, 24);
  &.mobile-menu-active {
    overflow-y: hidden;
    .overlay {
      background-color: $black;
      opacity: 0.4;
      position: fixed;
      z-index: 2;
      left: 0;
      top: 0;
      height: 100vh;
      width: 100%;
    }
  }
}

[data-whatinput='touch'],
[data-whatinput='mouse'] {
  a,
  button {
    &:focus,
    &:active {
      outline: 0;
      // border-color: transparent;
      box-shadow: none;
    }
  }
  :focus {
    outline: 0;
  }
}
[data-whatinput='keyboard'] {
  a,
  button,
  input,
  textarea {
    &:focus,
    &:active {
      outline-offset: 1px;
      outline: royalblue auto 1px;
    }
  }
  .select-wrapper .select-inner,
  .form-file-wrapper,
  .box-radios .inner,
  .image-radios label {
    &:focus-within {
      outline-offset: 1px;
      outline: royalblue auto 1px;
    }
  }
  .image-radios label {
    &:focus-within {
      outline-offset: 9px;
    }
  }
  [type='checkbox'],
  [type='radio'] {
    &:focus {
      & + svg {
        outline-offset: 1px;
        outline: royalblue auto 1px;
        [data-whatintent='mouse'] & {
          outline: none;
        }
      }
      // &:not(:focus-visible) + svg {
      // }
    }
  }
  :focus {
    outline: 0;
  }
}
[data-whatintent='mouse'] {
  input,
  textarea {
    &:focus,
    &:active {
      outline: none;
    }
  }
}

// [id='edit-link'] {
//   position: fixed;
//   bottom: rem-calc(10);
//   left: rem-calc(10);
//   padding: rem-calc(2 15);
//   font-size: rem-calc(12);
//   border: rem-calc(1) solid $dark-green;
//   border-radius: rem-calc(5);
//   color: $very-dark-green;
//   background: $white;
//   z-index: 9999;
// }

// .top {
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   margin-top: rem-calc(40);
//   margin-bottom: rem-calc(40);
//   font-weight: bold;
//   /* 26 to match height of svg so vertical alignment works nicely */
//   a {
//     @include vertical-rhythm(17, 24);
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
//     &:hover,
//     &:active {
//       text-decoration: none;
//     }
//     .svg-icon {
//       height: rem-calc(28);
//       width: rem-calc(28);
//       margin-right: rem-calc(6);
//     }
//   }
// }
