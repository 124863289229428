.button {
  cursor: pointer;
  text-decoration: none; /* for when we use the class on <a> tags. */
  display: inline-flex;

  &.js-hide,
  .hidden {
    display: none !important;
  }

  align-items: center;
  justify-content: center;
  height: rem-calc(54);
  @include vertical-rhythm(18, 21);
  padding: rem-calc(0 29);
  width: auto;
  background-color: $btn-bg;
  color: $btn-fg;
  border: rem-calc(1) solid $btn-bg;
  font-weight: bold;
  .loaded & {
    transition: all $medium-slow $easing;
  }

  &:hover,
  &:active {
    background-color: $btn-bg-hover;
    color: $btn-fg;
  }

  // &.white-border {
  //   border-color: $btn-fg;
  //   &:hover,
  //   &:active {
  //     color: $dark-grey;
  //     background-color: $btn-fg;
  //     border-color: $btn-fg;
  //   }
  // }

  &.secondary {
    background-color: $btn-fg;
    color: $btn-bg;
    border-color: $btn-bg;

    &:hover,
    &:active {
      color: $btn-fg;
      background-color: $btn-bg-hover;
      border-color: $btn-bg-hover;
    }
  }

  &.alternative {
    background-color: $metallic-orange;
    color: $white;
    border-color: $metallic-orange;

    &:hover,
    &:active {
      color: $white;
      background-color: $yellow-orange;
      border-color: $yellow-orange;
    }
  }

  // &.transparent {
  //   background-color: transparent;
  // }

  // &.very-light-green {
  //   background-color: $very-light-grey;
  //   border-color: $very-light-grey;
  //   color: $dark-grey;

  //   &:hover,
  //   // &:focus,
  //   &:active {
  //     background-color: $very-light-grey;
  //   }
  // }

  &.no-button {
    @include vertical-rhythm(18, 24);
    color: $btn-bg;
    background-color: transparent;
    text-decoration: none;
    font-weight: bold;
    padding: 0;
    height: auto;
    border: none;
    &:hover,
    &:active {
      color: $metallic-orange;
    }
  }

  &.text-left {
    text-align: left;

    &.with-icon.icon-right {
      justify-content: space-between;
    }

    &.with-icon.icon-left {
      justify-content: start;
    }
  }

  &.with-icon {
    &::before,
    &::after {
      width: rem-calc(16);
      height: rem-calc(16);
      // transform: translateY(#{rem-calc(-2)});
    }

    &.icon-right {
      &::after {
        content: $icon-arrow-right-white;
        margin-left: rem-calc(16);
      }
    }

    &.icon-left {
      &::before {
        content: $icon-arrow-left-white;
        margin-right: rem-calc(16);
      }
    }
    &.no-button {
      &.icon-right {
        &::after {
          content: $icon-arrow-right-catalina-blue;
        }
        &:hover,
        &:active {
          &::after {
            content: $icon-arrow-right-metallic-orange;
          }
        }
      }
      &.icon-left {
        &::before {
          content: $icon-arrow-left-catalina-blue;
        }
        &:hover,
        &:active {
          &::before {
            content: $icon-arrow-left-metallic-orange;
          }
        }
      }
    }
    // &.icon-top {
    //   flex-direction: column;

    //   .svg-icon {
    //     width: rem-calc(21);
    //     height: rem-calc(21);
    //   }

    //   .text {
    //     @include vertical-rhythm(12, 16);
    //     margin-top: rem-calc(2);
    //     font-weight: 600;
    //   }
    // }
  }
  // &.form-like {
  //   border-bottom: rem-calc(2) solid $hilite-yellow;
  //   @include vertical-rhythm(15, 20);
  //   padding: rem-calc(0 0 2 0);
  //   @include breakpoint(large) {
  //     @include vertical-rhythm(16, 20);
  //     padding: rem-calc(0 12 10 4);
  //   }
  //   &:hover,
  //   &:active {
  //     text-decoration: none;
  //   }
  // }
  &.icon-only {
    background-color: transparent;
    padding: 0;
    border: none;
    outline: none;
    color: inherit;
    &::before {
      width: 100%;
      height: 100%;
    }
    &:hover,
    // &:focus,
    &:active {
      background-color: transparent;
      padding: 0;
      border: none;
      outline: none;
      text-decoration: none;
    }
  }
  // &.linear-nav {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  //   background-color: transparent;
  //   border: none;
  //   padding: 0;
  //   outline: none;
  //   height: auto;
  //   color: $dark-grey;
  //   .icon-wrapper {
  //     display: inline-flex;
  //     align-items: center;
  //     justify-content: center;
  //     height: rem-calc(18);
  //     width: rem-calc(18);
  //     border: rem-calc(2) solid $dark-grey;
  //     border-radius: 100%;
  //     .svg-icon {
  //       height: rem-calc(16);
  //       width: rem-calc(16);
  //     }
  //   }
  //   .text {
  //     padding-top: rem-calc(16);
  //   }
  //   @include breakpoint(large) {
  //     display: inline-block;
  //     .svg-icon {
  //       transform: rotate(90deg);
  //     }
  //     // &.next .svg-icon {
  //     //   transform: rotate(90deg);
  //     // }
  //     .text {
  //       @include element-invisible;
  //     }
  //   }
  //   &:hover,
  //   &:active {
  //     .icon-wrapper {
  //       background-color: $dark-grey;
  //       .svg-icon {
  //         color: $btn-fg;
  //       }
  //     }
  //   }
  //   &.disabled {
  //     visibility: hidden;
  //   }
  // }
  // &.small {
  //   @include vertical-rhythm(16, 20);
  //   padding: rem-calc(12 16);
  //   height: auto;
  // }
  // &.link {
  //   &:hover,
  //   // &:focus,
  //   &:active {
  //     color: $btn-fg;
  //     text-decoration: none;
  //   }
  //   &.white-border {
  //     &:hover,
  //     // &:focus,
  //     &:active {
  //       color: $dark-grey;
  //     }
  //   }
  // }
}
//   &.full {
//     width: 100%;
//   }
//   &.xlarge {
//     min-height: rem-calc(66);
//     .no-cssgrid & {
//       height: rem-calc(66);
//     }
//     min-width: rem-calc(260);
//     @include vertical-rhythm(27, 36);
//     border-radius: rem-calc(10);
//     &.create {
//       min-width: 0;
//       width: 100%;
//     }
//   }
//   &.large {
//     min-height: rem-calc(54);
//     .no-cssgrid & {
//       height: rem-calc(54);
//     }
//     min-width: rem-calc(171);
//     @include vertical-rhythm(20, 24);
//     border-radius: rem-calc(10);
//   }
//   &.small {
//     @include vertical-rhythm(14, 18);
//     border-radius: rem-calc(6);
//     min-height: rem-calc(30);
//     .no-cssgrid & {
//       height: rem-calc(30);
//     }
//     min-width: rem-calc(79);
//     padding: rem-calc(6);
//   }
//   &.small-to-default {
//     @include breakpoint(xmedium down) {
//       @include vertical-rhythm(14, 18);
//       border-radius: rem-calc(6);
//       min-height: rem-calc(30);
//       .no-cssgrid & {
//         height: rem-calc(30);
//       }
//       min-width: rem-calc(79);
//       padding: rem-calc(6);
//     }
//     .reveal & {
//       @include vertical-rhythm(14, 18);
//       border-radius: rem-calc(6);
//       min-height: rem-calc(30);
//       .no-cssgrid & {
//         height: rem-calc(30);
//       }
//       min-width: rem-calc(79);
//       padding: rem-calc(6);
//     }
//     // .reveal .submit-wrapper & {
//     //   @include breakpoint(xmedium down) {
//     //     @include vertical-rhythm(14, 18);
//     //     border-radius: rem-calc(6);
//     //     min-height: rem-calc(30);
//     //     .no-cssgrid & {
//     //       height: rem-calc(30);
//     //     }
//     //     min-width: rem-calc(79);
//     //     padding: rem-calc(6);
//     //   }
//     //   border-radius: rem-calc(8);
//     //   min-height: rem-calc(42);
//     //   .no-cssgrid & {
//     //     height: rem-calc(42);
//     //   }
//     //   min-width: rem-calc(115);
//     //   padding: rem-calc(8 17);
//     // }
//   }
//   &.small-to-xlarge {
//     @include vertical-rhythm(14, 18);
//     border-radius: rem-calc(6);
//     min-height: rem-calc(30);
//     .no-cssgrid & {
//       height: rem-calc(30);
//     }
//     min-width: rem-calc(79);
//     padding: rem-calc(6);
//     @include breakpoint(medium) {
//       min-height: rem-calc(42);
//       .no-cssgrid & {
//         height: rem-calc(42);
//       }
//       min-width: rem-calc(115);
//       @include vertical-rhythm(19, 24);
//       padding: rem-calc(8 17);
//       border-radius: rem-calc(8);
//     }
//     @include breakpoint(large) {
//       min-height: rem-calc(54);
//       .no-cssgrid & {
//         height: rem-calc(54);
//       }
//       min-width: rem-calc(171);
//       @include vertical-rhythm(20, 24);
//       border-radius: rem-calc(10);
//     }
//     @include breakpoint(xlarge) {
//       min-height: rem-calc(66);
//       .no-cssgrid & {
//         height: rem-calc(66);
//       }
//       min-width: rem-calc(260);
//       @include vertical-rhythm(27, 36);
//       border-radius: rem-calc(10);
//     }
//   }
//   &.default-to-large {
//     @include breakpoint(medium) {
//       min-height: rem-calc(54);
//       .no-cssgrid & {
//         height: rem-calc(54);
//       }
//       min-width: rem-calc(171);
//       @include vertical-rhythm(20, 24);
//       border-radius: rem-calc(10);
//     }
//   }
//   &.default-to-xlarge {
//     @include breakpoint(medium) {
//       min-height: rem-calc(66);
//       .no-cssgrid & {
//         height: rem-calc(66);
//       }
//       min-width: rem-calc(260);
//       @include vertical-rhythm(27, 36);
//       border-radius: rem-calc(10);
//     }
//   }
//   &.green {
//     background-color: $dark-grey;
//     border-color: $dark-grey;
//     &:hover,
//     &:focus,
//     &:active {
//       background-color: darken($dark-grey, $darken);
//       border-color: darken($dark-grey, $darken);
//       color: $btn-fg;
//     }
//   }
//   &.basic {
//     background-color: $basic-green;
//     border-color: $basic-green;
//     &:hover,
//     &:focus,
//     &:active {
//       background-color: darken($basic-green, $darken);
//       border-color: darken($basic-green, $darken);
//       color: $btn-fg;
//     }
//   }
//   &.standard {
//     background-color: $standard-green;
//     border-color: $standard-green;
//     &:hover,
//     &:focus,
//     &:active {
//       background-color: darken($standard-green, $darken);
//       border-color: darken($standard-green, $darken);
//       color: $btn-fg;
//     }
//   }
//   &.premium {
//     background-color: $premium-green;
//     border-color: $premium-green;
//     &:hover,
//     &:focus,
//     &:active {
//       background-color: darken($premium-green, $darken);
//       border-color: darken($premium-green, $darken);
//       color: $btn-fg;
//     }
//   }
//   &.secondary {
//     color: $mid-blue;
//     background-color: $btn-fg;
//     border-color: $mid-blue;
//     &:hover,
//     &:focus,
//     &:active {
//       background-color: $mid-blue;
//       border-color: $mid-blue;
//       color: $btn-fg;
//     }
//   }
//   &.tertiary {
//     color: $dark-grey;
//     border-color: $dark-grey;
//     background-color: $blue-grey;
//     &:hover,
//     &:focus,
//     &:active {
//       background-color: darken($blue-grey, $darken);
//       border-color: darken($dark-grey, $darken);
//       color: darken($dark-grey, $darken);
//     }
//   }
//   &.inverted {
//     color: $mid-blue;
//     background-color: $btn-fg;
//     border-color: $btn-fg;
//     &:hover,
//     &:focus,
//     &:active {
//       color: $btn-fg;
//       background-color: $mid-blue;
//       border-color: $btn-fg;
//     }
//   }
//   &.transparent,
//   &.transparent.icon-only {
//     color: $mid-blue;
//     background-color: transparent;
//     border: rem-calc(1) solid $mid-blue;
//     &:hover,
//     &:focus,
//     &:active,
//     &.is-open {
//       background-color: $mid-blue;
//       border-color: $mid-blue;
//       color: $btn-fg;
//     }
//   }
//   &.facebook {
//     color: $dark-blue;
//     background-color: transparent;
//     border-color: $dark-blue;
//     border-width: rem-calc(2);
//     &:hover,
//     &:focus,
//     &:active {
//       background-color: $dark-blue;
//       border-color: $dark-blue;
//       color: $btn-fg;
//     }
//   }
//   &.share {
//     @include vertical-rhythm(14, 18);
//     min-height: rem-calc(30);
//     .no-cssgrid & {
//       height: rem-calc(30);
//     }
//     @include breakpoint(xmedium down) {
//       border-radius: rem-calc(6);
//       min-width: rem-calc(79);
//       padding: rem-calc(6);
//     }
//     @include breakpoint(medium) {
//       min-height: rem-calc(48);
//       .no-cssgrid & {
//         height: rem-calc(48);
//       }
//       @include vertical-rhythm(22, 30);
//     }
//     .addtoany_list & .addtoany_list a:hover & span {
//       // overrides a module's styles
//       opacity: 1;
//     }
//   }
//   &.facebook-share {
//     background-color: $facebook-blue;
//     border-color: $facebook-blue;
//     &:hover,
//     &:focus,
//     &:active {
//       background-color: darken($facebook-blue, $darken);
//       border-color: darken($facebook-blue, $darken);
//     }
//   }
//   &.twitter {
//     background-color: $twitter-blue;
//     border-color: $twitter-blue;
//     &:hover,
//     &:focus,
//     &:active {
//       background-color: darken($twitter-blue, $darken);
//       border-color: darken($twitter-blue, $darken);
//     }
//   }
//   &[disabled] {
//     cursor: not-allowed;
//     background-color: $disabled-bg;
//     border-color: $disabled-bg;
//     &:hover,
//     &:focus,
//     &:active {
//       background-color: $disabled-bg;
//       border-color: $disabled-bg;
//       color: $disabled-fg;
//     }
//   }

//   &.with-icon {
//     .svg-icon {
//       width: 1em;
//       height: 1em;
//     }
//     &.icon-right .svg-icon {
//       margin-left: rem-calc(14);
//       margin-right: 0;
//     }
//     &.icon-left .svg-icon {
//       margin-right: rem-calc(14);
//       margin-left: 0;
//     }
//     &.share {
//       @include breakpoint(medium) {
//         .svg-icon {
//           width: rem-calc(28);
//           height: rem-calc(28);
//         }
//       }
//       &.icon-left .svg-icon {
//         margin-right: rem-calc(6);
//         @include breakpoint(medium) {
//           margin-right: rem-calc(8);
//         }
//       }
//     }
//     &.drop-down-open {
//       color: $mid-blue;
//       background-color: $btn-fg;
//       border: rem-calc(1) solid $mid-blue;
//       // height: rem-calc(42);
//       width: rem-calc(60);
//       .svg-icon {
//         transform: rotate(0);
//         transition: transform $fast $easing;
//       }
//       &.is-open {
//         .svg-icon {
//           transform: rotate(180deg);
//         }
//       }
//       &:hover,
//       &:focus,
//       &:active {
//         background-color: $mid-blue;
//         border-color: $mid-blue;
//         color: $btn-fg;
//       }
//       @include breakpoint(xmedium down) {
//         @include vertical-rhythm(14, 18);
//         border-radius: rem-calc(6);
//         height: rem-calc(30);
//         width: rem-calc(43);
//         padding: rem-calc(6);
//       }
//     }
//   }
//   &.icon-only {
//     background: transparent;
//     padding: 0;
//     @include vertical-rhythm(16, 16); // default. Set font size to control size
//     height: 1em;
//     min-height: 0;
//     min-width: 0;
//     border: none;
//     .svg-icon {
//       height: 1em;
//       width: 1em;
//     }
//     &:hover,
//     &:focus,
//     &:active {
//       background-color: transparent;
//       color: darken($btn-bg, $darken);
//     }
//     &.remove {
//       font-size: rem-calc(20rem);
//     }
//   }
//   &.no-button {
//     @include vertical-rhythm(17, 24);
//     color: $mid-blue;
//     background-color: transparent;
//     font-weight: bold;
//     text-decoration: none;
//     padding: 0;
//     min-height: 0;
//     min-width: 0;
//     border: none;
//     .no-cssgrid & {
//       height: auto;
//     }
//     &:hover,
//     &:focus,
//     &:active {
//       color: darken($mid-blue, $darken);
//       text-decoration: underline;
//     }
//     &.alternative {
//       color: $dark-grey;
//       &:hover,
//       &:focus,
//       &:active {
//         color: darken($dark-grey, $darken);
//       }
//     }
//     &.with-icon {
//       .svg-icon,
//       svg {
//         height: 1em;
//         width: 1em;
//       }
//       &.icon-right {
//         .svg-icon {
//           margin-left: rem-calc(9);
//           position: relative;
//           top: rem-calc(-1);
//         }
//       }
//       &.icon-left {
//         display: inline-block;
//         position: relative;
//         padding-left: calc(1em + #{rem-calc(9)});
//         text-align: left;
//         .svg-icon {
//           position: absolute;
//           left: 0;
//           top: rem-calc(2);
//           margin-right: 0;
//         }
//       }
//     }
//     &.small-to-default {
//       @include breakpoint(xmedium down) {
//         @include vertical-rhythm(14, 18);
//         &.with-icon {
//           padding-left: calc(1em + #{rem-calc(6)});
//           .svg-icon {
//             top: rem-calc(1);
//           }
//         }
//       }
//       .reveal & {
//         @include vertical-rhythm(14, 18);
//         min-height: 0;
//         min-width: 0;
//         border: none;
//         .no-cssgrid & {
//           height: auto;
//         }
//         &.with-icon {
//           padding: 0 0 0 calc(1em + #{rem-calc(6)});
//           .svg-icon {
//             top: rem-calc(1);
//           }
//         }
//       }
//     }
//   }
//   &.add-another-section {
//     @include vertical-rhythm(20, 24);
//   }
// }
