.back-link {
  display: flex;
  flex: 1 1 auto;
  font-family: $heading-font;
  color: $dark-cornflower-blue;
  text-decoration: none;
  @include vertical-rhythm(32, 42);
  margin-bottom: rem-calc(36);
  &::before {
    content: $icon-arrow-left-dark-cornflower-blue;
    flex: 0 0 rem-calc(30);
    width: rem-calc(30);
    height: rem-calc(30);
    transform: translateY(rem-calc(5));
    margin-right: rem-calc(18);
  }
  &:hover,
  &:active {
    &::before {
      content: $icon-arrow-left-metallic-orange;
    }
  }
}
