.picklists {
    $this: &;
  
    &__page-title-two-column {
        margin-top: 2rem;
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
  
    &__button-general {
        background-color: $white;
        border-color: $quicksilver;
        color: $quicksilver;
        &:hover {
            background-color: $white;
            border-color: $quicksilver;
            color: $quicksilver;
            cursor: url('../../costbuilder/images/prohibited.svg'), auto;
        }
    }

    &__top-link-container {
        max-width: 80rem;
        margin-left: auto;
        margin-right: auto;
    }

    &__button-export {
        background-color: $white;
        border-color: $catalina-blue;
        color: $catalina-blue;
        &:hover {
            background-color: $catalina-blue;
            border-color: $catalina-blue;
            color: $white;
        }
    }

    &__button-main {
        background-color: $catalina-blue;
        border-color: $catalina-blue;
        color: $white;
        &:hover {
            background-color: $white;
            border-color: $catalina-blue;
            color: $catalina-blue;
        }
    }

    &__space-between-buttons {
        margin-right: 1rem;
    }

    &__page-title-text {
        margin-bottom: 0rem;
    }

    &__link-text {
        color: $tiffany-blue;
        border: none;
        text-decoration: underline;
        cursor: pointer;
    }

    &__link-container {
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__filter-input {
        border: 0.1rem solid #0e256a;
        height: 2.5rem;
        width: 20rem;
        margin-bottom: 0.5rem;
        padding-left:0.6rem;
    }

    &__smaller-button {
        height: 2.5rem;
        margin-bottom: 0.5rem;
    }

    &__picklists-table-head {
        border: none;
        background-color: $grey;
    }

    &__table-th {
        padding: 10px 4px;
        border: none;
        border-left: 0.0625rem solid #fff;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        vertical-align: middle;
        text-align: center;
        background-color: #dbdcdf;
    }

    &__table-td {
        border-bottom-color: #dbdcdf;
        padding: 0.75rem 1.125rem;
        font-size: 0.875rem;
        vertical-align: middle;
        white-space: normal;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }

    &__select-column-padding {
        padding-left: 1.75rem;
    }

    &__select-column {
        text-align: center;
        width: 5rem;
    }

    &__rates-column {
        text-align: center;
        width: 7rem;
    }

    &__date-column {
        text-align: center;
        width: 7rem;
    }

    &__date-column-header {
        text-align: center;
        width: 7rem;
        cursor: pointer;
    }

    &__name-cursor {
        cursor: pointer;
    }
    
    &__name-cursor:hover {
        color: $catalina-blue;
        text-decoration: underline;
    }

    &__project-name-column {
        text-align: left;
        margin-left: 1rem;
        max-width: 20rem;
    }

    &__project-name-heading-column {
        text-align: center;
        max-width: 20rem;
    }
    
    &__picklists-container {
        max-width: 80rem;
    }

    &__popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999; /* Ensure this is below __popup-modal's z-index which is 1000 */
    }

    &__popup-modal {
        display: none;
        border: 0.1rem solid $oxford-blue;
        background: $white;
        position: absolute;
        top: 20rem;
        left: 50%;
        translate: -50% -50%;
        width: 40%;
        height: auto;
        overflow: auto;
        padding-left: 0%;
        padding-right: 0%;
        z-index: 1000;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
        opacity: 1;
    }

    &__popup-modal-title {
        color: $catalina-blue;
        font-weight: bold;
        font-size: x-large;
        text-align: center;
    }

    &__popup-modal-description {
        color: $catalina-blue;
        font-size: large;
        margin-top: 1rem;
        margin-bottom: 3rem;
        text-align: center;
    }

    &__buttons-container {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
    }

    &__button-cancel {
        width: 6rem;
        margin-right: 0rem;
    }

    &__modal {
        display: flex;
        flex-direction: column;
        margin: 1rem;
    }

    &__check-box {
        position: relative;
        opacity: 1;
    }

    &__visible {
        display: block;
    }

    &__name-input {
        width: 100%;
    }

    &__length-display {
        margin-top: 0.5rem;
        text-align: center;
    }
}