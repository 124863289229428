@import "../../vendor/foundation-sites/scss/util/breakpoint";

.ad-block {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30%;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding-bottom: 3rem;
}
.ad-header{
  padding-top: 3rem;
  overflow-wrap: break-word;
  width:80%;
}
.ad-block-image {
  display: flex;
  width: 1220px;
  height: 344px; 
  background-image: var(--ad-desktop-img);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.ad-block-image.small-image {
  background-size: contain;
  background-position: center;
}

@media(max-width: map-get($breakpoints,xlarge)){
  .ad-block-image{
    width: 100%;
    height: 50vh;
  }
}
