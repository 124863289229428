@keyframes expand-down {
  from {
    max-height: rem-calc(0);
  }
  to {
    max-height: rem-calc(1000);
  }
}
@keyframes contract-up {
  from {
    max-height: rem-calc(1000);
  }
  to {
    max-height: rem-calc(0);
  }
}
// @keyframes fb-expand-down {
//   from {
//     max-height: 0;
//   }
//   to {
//     max-height: rem-calc(1000);
//   }
// }
// @keyframes fb-contract-up {
//   from {
//     max-height: rem-calc(1000);
//   }
//   to {
//     max-height: 0;
//   }
// }
// @keyframes fb-slide-in {
//   from {
//     transform: translateX(rem-calc($mw));
//   }
//   to {
//     transform: translateX(0);
//   }
// }
// @keyframes fb-slide-out {
//   from {
//     transform: translateX(0);
//   }
//   to {
//     transform: translateX(rem-calc($mw));
//   }
// }
// @keyframes fb-fade-in {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }
// @keyframes fb-fade-out {
//   from {
//     opacity: 1;
//   }
//   to {
//     opacity: 0;
//   }
// }
// @keyframes fb-mobile-in {
//   from {
//     transform: translateX(100%);
//     opacity: 0;
//   }
//   to {
//     transform: translateX(0);
//     opacity: 1;
//   }
// }
// @keyframes fb-mobile-out {
//   from {
//     transform: translateX(0);
//     opacity: 1;
//   }
//   to {
//     transform: translateX(100%);
//     opacity: 0;
//   }
// }
@keyframes slide-in {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-out {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
@keyframes slide-down {
  from {
    transform: translateY(-20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-up {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-20%);
    opacity: 0;
  }
}
// @keyframes fs-fade-in {
//   from {
//     transform: translateY(rem-calc(50));
//     opacity: 0;
//   }
//   to {
//     transform: translateY(0);
//     opacity: 1;
//   }
// }
// @keyframes fs-fade-out {
//   from {
//     transform: translateY(0);
//     opacity: 1;
//   }
//   to {
//     transform: translateY(rem-calc(50));
//     opacity: 0;
//   }
// }
// @keyframes loader {
//   0% {
//     transform: rotate(0);
//   }
//   100% {
//     transform: rotate(359deg);
//   }
// }
