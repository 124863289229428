p {
  @include vertical-rhythm(18, 30);
  margin: 0;

  &:not(:last-child) {
    margin-bottom: rem-calc(30);
  }

  &.intro {
    // max-width: rem-calc(1176);
    // font-weight: 300;
    @include vertical-rhythm(20, 30);
    margin-bottom: rem-calc(30);
  }
}
div.block + div.block {
  margin-top: rem-calc(30);
}
a {
  span.last {
    white-space: nowrap;
  }
  &.loaded {
    transition: all $fast $easing;
  }
  // @include linkhover;
  color: $catalina-blue;
  text-decoration: underline;

  &:hover,
  &:active {
    color: $metallic-orange;
  }
}
