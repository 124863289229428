ul,
ol,
.no-bullets .accordion-content ul {
  padding: 0;
  margin: rem-calc(0 0 24);
  &:last-child {
    margin-bottom: 0;
  }
  > li {
    @include vertical-rhythm(18, 30);
    margin-bottom: rem-calc(20);
    &:last-child {
      margin-bottom: 0;
    }
    > ul,
    > ol {
      margin-top: rem-calc(20);
    }
  }
}

ul,
.no-bullets .accordion-content ul {
  list-style: none;
  margin-left: rem-calc(30);
  > li {
    padding-left: rem-calc(24);
    position: relative;
    &::before {
      content: '';
      position: absolute;
      background-color: $dark-cornflower-blue;
      width: rem-calc(6);
      height: rem-calc(6);
      left: 0;
      top: rem-calc(12);
    }
  }
}
ol {
  padding-left: rem-calc(20);
  > li {
    padding-left: rem-calc(12);
    &::marker {
      font-weight: bold;
    }
  }
}
.no-bullets {
  list-style: none;
  margin: 0;
  > li {
    position: static;
  }
  li > ul {
    margin: 0;
  }
  > li,
  > li li {
    padding-left: 0;
    margin: 0;
    &::before {
      display: none;
    }
  }
  .accordion-content ul li {
    &::before {
      display: unset;
    }
  }
}
ol.no-bullets {
  padding-left: 0;
}
dl,
dt,
dd {
  margin: 0;
}
dt,
dd {
  @include vertical-rhythm(18, 30);
}

dt {
  font-weight: bold;
  display: inline-block;
  padding-right: rem-calc(3);
}
dd {
  display: inline;
  &::after {
    content: '\A';
    white-space: pre;
    height: rem-calc(30);
    display: block;
  }
}
