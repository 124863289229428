// Local variables
// Variables in _foundation-settings.scss are largely used for Foundation mixins and functions so set the project specific ones here

$font-family: sans-serif;
$body-font: myriad-pro, $font-family;
$heading-font: Montserrat, $font-family;
$other-font: Roboto, $font-family;

// Colours
// names come from https://www.color-name.com

// old ones to delete
$black: #000000;
// $white: #ffffff;
// $off-white: #f5f5f5;
// $off-black: #1a1a1a;
// $grey: #aaaaaa;
// $mid-grey: #999999;
// $darkish-grey: #4f4f4f;
// $dark-grey: #333333;
// $light-grey: #efefef;
// $lighter-grey: #b5b5b5;
// $red: #82170f;
// // $light-red: #e73c4e;
// $logo-red: #d5151a;
// $hilite-yellow: #f7e377;
// // $light-green: #98fb98;
// $green: #007a4b;

// greyscale, sort of
$rich-black: #05190f;
$wenge: #655b58;
$white: #ffffff;
$gainsboro: #dbdcdf;
$grey: #b8babe;
$quicksilver: #a39e9e;
$bright-grey: #ebebed;
$anti-flash-white: #f2f3f4;

// blues
$dark-cornflower-blue: #1e4196;
$catalina-blue: #0e256a;
$stpatricks-blue: #173584;
$alice-blue: #ecf8fe;
$fresh-air: #a6e1fa;
$green-blue: #0e6ba8;
$tiffany-blue: #00bfb3;
$water: #dcf3fd;
$medium-turquoise: #4ad1c9;
$non-photo-blue: #9ce6e1;
$beau-blue: #bad1eb;
$oxford-blue: #001c51;
$air-force-blue: #003484;

//oranges
$metallic-orange: #ea6a0f;
$yellow-orange: #ff990f;
$blanched-almond: #ffeacc;
$caramel: #ffd498;
$persimmon: #e74e00;

$red: #ff0000;
$darker-red: #cc1a27;

$body-text: $rich-black;
$body-bg: $white;
$input-bg: $anti-flash-white;
$input-border: $quicksilver;
$input-border-foc: $catalina-blue;
$input-err: $red;
$input-success: #4ad160;
// $disabled-bg: $off-white;
// $disabled-fg: $grey;

// box colours
// $box-red: $red;
// $box-grey: $darkish-grey;
// $box-brown: #bd7b00;
// $box-light-red: #f05400;

// button-colours
$btn-bg: $catalina-blue;
$btn-fg: $white;
$btn-bg-hover: $dark-cornflower-blue;

//gradients
$gradient-blue: linear-gradient(
  90deg,
  $catalina-blue,
  #0e266b,
  #10286f,
  #112b74,
  #132e7a,
  #15327f,
  #163483,
  $stpatricks-blue,
  #163483,
  #15327f,
  #132e7a,
  #112b74,
  #10286f,
  #0e266b,
  $catalina-blue
);

// box shadows
$box-shadow: rem-calc(0 10 25 0) rgba($catalina-blue, 0.2);
$black-box-shadow: rem-calc(0 10 20 0) rgba($black, 0.16);
$dark-grey-box-shadow: rem-calc(0 6 12 0) rgba($grey, 0.26);
$input-box-shadow: rem-calc(3 3 53 -8) rgba($black, 0.61);
$input-error-box-shadow: rem-calc(3 3 53 -8) rgba($red, 0.61);

// text shadow
$text-shadow: rem-calc(0 0 5) rgba($black, 0.5);
$text-shadow-small: rem-calc(0 0 5) rgba($black, 0.8);

// animations/transitions
$easing: ease-in-out;
$fast: 0.3s;
$medium-slow: 0.5s;
$slow: 1s;

// fixed header heights
$header-top-h: 150;
$header-bottom-h: 114;
$header-top-h-smaller: 72;
$header-bottom-h-smaller: 84;
$header-h: $header-top-h + $header-bottom-h;
$header-h-smaller: $header-top-h-smaller + $header-bottom-h-smaller;
$header-mobile-h: 150;
$header-mobile-h-smaller: 108;

$logo-ratio: math.div(322.7, 63.5);
$qv-logo-ratio: math.div(100, 84.5);
$banner-ratio: math.div(880, 330);
$welcome-ratio: math.div(958, 646);

// Data URI SVG icon variables
$icon-arrow-left-catalina-blue: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%230e256a' d='M51.3 96.7l4.2-4.2c1-1 1-2.6 0-3.6L22.3 55.6h73.2c1.4 0 2.6-1.2 2.6-2.6v-6c0-1.4-1.2-2.6-2.6-2.6H22.3l33.2-33.2c1-1 1-2.6 0-3.6l-4.2-4.2c-1-1-2.6-1-3.6 0L2.8 48.2c-1 1-1 2.6 0 3.6l44.9 44.9c.9 1 2.6 1 3.6 0z'/%3e%3c/svg%3e");
$icon-arrow-left-dark-cornflower-blue: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%231e4196' d='M51.3 96.7l4.2-4.2c1-1 1-2.6 0-3.6L22.3 55.6h73.2c1.4 0 2.6-1.2 2.6-2.6v-6c0-1.4-1.2-2.6-2.6-2.6H22.3l33.2-33.2c1-1 1-2.6 0-3.6l-4.2-4.2c-1-1-2.6-1-3.6 0L2.8 48.2c-1 1-1 2.6 0 3.6l44.9 44.9c.9 1 2.6 1 3.6 0z'/%3e%3c/svg%3e");
$icon-arrow-left-metallic-orange: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%23ea6a0f' d='M51.3 96.7l4.2-4.2c1-1 1-2.6 0-3.6L22.3 55.6h73.2c1.4 0 2.6-1.2 2.6-2.6v-6c0-1.4-1.2-2.6-2.6-2.6H22.3l33.2-33.2c1-1 1-2.6 0-3.6l-4.2-4.2c-1-1-2.6-1-3.6 0L2.8 48.2c-1 1-1 2.6 0 3.6l44.9 44.9c.9 1 2.6 1 3.6 0z'/%3e%3c/svg%3e");
$icon-arrow-left-white: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%23fff' d='M51.3 96.7l4.2-4.2c1-1 1-2.6 0-3.6L22.3 55.6h73.2c1.4 0 2.6-1.2 2.6-2.6v-6c0-1.4-1.2-2.6-2.6-2.6H22.3l33.2-33.2c1-1 1-2.6 0-3.6l-4.2-4.2c-1-1-2.6-1-3.6 0L2.8 48.2c-1 1-1 2.6 0 3.6l44.9 44.9c.9 1 2.6 1 3.6 0z'/%3e%3c/svg%3e");
$icon-arrow-right-catalina-blue: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%230e256a' d='M48.7 3.3l-4.2 4.2c-1 1-1 2.6 0 3.6l33.2 33.2H4.6C3.2 44.4 2 45.6 2 47v6c0 1.4 1.2 2.6 2.6 2.6h73.2L44.5 88.8c-1 1-1 2.6 0 3.6l4.2 4.2c1 1 2.6 1 3.6 0l44.9-44.9c1-1 1-2.6 0-3.6L52.4 3.3c-1-1-2.7-1-3.7 0z'/%3e%3c/svg%3e");
$icon-arrow-right-metallic-orange: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%23ea6a0f' d='M48.7 3.3l-4.2 4.2c-1 1-1 2.6 0 3.6l33.2 33.2H4.6C3.2 44.4 2 45.6 2 47v6c0 1.4 1.2 2.6 2.6 2.6h73.2L44.5 88.8c-1 1-1 2.6 0 3.6l4.2 4.2c1 1 2.6 1 3.6 0l44.9-44.9c1-1 1-2.6 0-3.6L52.4 3.3c-1-1-2.7-1-3.7 0z'/%3e%3c/svg%3e");
$icon-arrow-right-white: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%23fff' d='M48.7 3.3l-4.2 4.2c-1 1-1 2.6 0 3.6l33.2 33.2H4.6C3.2 44.4 2 45.6 2 47v6c0 1.4 1.2 2.6 2.6 2.6h73.2L44.5 88.8c-1 1-1 2.6 0 3.6l4.2 4.2c1 1 2.6 1 3.6 0l44.9-44.9c1-1 1-2.6 0-3.6L52.4 3.3c-1-1-2.7-1-3.7 0z'/%3e%3c/svg%3e");
$icon-arrow-up-dark-cornflower-blue: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%231e4196' d='M3.3 51.3l4.2 4.2c1 1 2.6 1 3.6 0l33.2-33.2v73.2c0 1.4 1.2 2.6 2.6 2.6h6c1.4 0 2.6-1.2 2.6-2.6V22.3l33.2 33.2c1 1 2.6 1 3.6 0l4.2-4.2c1-1 1-2.6 0-3.6L51.8 2.8c-1-1-2.6-1-3.6 0L3.3 47.6c-1 1-1 2.7 0 3.7z'/%3e%3c/svg%3e");
$icon-arrow-up-metallic-orange: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%23ea6a0f' d='M3.3 51.3l4.2 4.2c1 1 2.6 1 3.6 0l33.2-33.2v73.2c0 1.4 1.2 2.6 2.6 2.6h6c1.4 0 2.6-1.2 2.6-2.6V22.3l33.2 33.2c1 1 2.6 1 3.6 0l4.2-4.2c1-1 1-2.6 0-3.6L51.8 2.8c-1-1-2.6-1-3.6 0L3.3 47.6c-1 1-1 2.7 0 3.7z'/%3e%3c/svg%3e");
$icon-chevron-down-dark-cornflower-blue: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%231e4196' d='M97.2 26.2l-1.5-1.5c-1-1-2.6-1-3.7 0L50 66.8 7.9 24.7c-1-1-2.6-1-3.7 0l-1.5 1.5c-1 1-1 2.6 0 3.7l45.4 45.4c1 1 2.6 1 3.7 0l45.4-45.4c1.1-1 1.1-2.7 0-3.7z'/%3e%3c/svg%3e");
$icon-chevron-down-light-white: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%23fff' d='M97.2 26.2l-1.5-1.5c-1-1-2.6-1-3.7 0L50 66.8 7.9 24.7c-1-1-2.6-1-3.7 0l-1.5 1.5c-1 1-1 2.6 0 3.7l45.4 45.4c1 1 2.6 1 3.7 0l45.4-45.4c1.1-1 1.1-2.7 0-3.7z'/%3e%3c/svg%3e");
$icon-chevron-down-light-wenge: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%23655b58' d='M97.2 26.2l-1.5-1.5c-1-1-2.6-1-3.7 0L50 66.8 7.9 24.7c-1-1-2.6-1-3.7 0l-1.5 1.5c-1 1-1 2.6 0 3.7l45.4 45.4c1 1 2.6 1 3.7 0l45.4-45.4c1.1-1 1.1-2.7 0-3.7z'/%3e%3c/svg%3e");
$icon-chevron-left-catalina-blue: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%230e256a' d='M22.4 46.3L65.1 3.5c2.1-2.1 5.4-2.1 7.5 0l5 5c2.1 2.1 2.1 5.4 0 7.5L43.7 50l33.9 34c2.1 2.1 2 5.4 0 7.5l-5 5c-2.1 2.1-5.4 2.1-7.5 0L22.4 53.7c-2-2-2-5.4 0-7.4z'/%3e%3c/svg%3e");
$icon-chevron-right-catalina-blue: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%230e256a' d='M77.6 53.7L34.9 96.5c-2.1 2.1-5.4 2.1-7.5 0l-5-5c-2.1-2.1-2.1-5.4 0-7.5l33.9-34-33.9-34c-2.1-2.1-2-5.4 0-7.5l5-5c2.1-2.1 5.4-2.1 7.5 0l42.7 42.7c2 2.1 2 5.5 0 7.5z'/%3e%3c/svg%3e");
$icon-download-dark-cornflower-blue: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%231e4196' d='M83.5 44.9L51.8 76.7c-1 1-2.6 1-3.6 0L16.5 44.9c-1-1-1-2.6 0-3.6l4.2-4.2c1-1 2.7-1 3.7 0l20.1 20.8V4.6C44.4 3.2 45.6 2 47 2h6c1.4 0 2.6 1.2 2.6 2.6v53.3l20.1-20.8c1-1 2.7-1 3.7 0l4.2 4.2c.9.9.9 2.6-.1 3.6zm5.1 42H11.4c-1.4 0-2.6 1.2-2.6 2.6v6c0 1.4 1.2 2.6 2.6 2.6h77.1c1.4 0 2.6-1.2 2.6-2.6v-6c0-1.5-1.1-2.6-2.5-2.6z'/%3e%3c/svg%3e");
$icon-download-metallic-orange: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%23ea6a0f' d='M83.5 44.9L51.8 76.7c-1 1-2.6 1-3.6 0L16.5 44.9c-1-1-1-2.6 0-3.6l4.2-4.2c1-1 2.7-1 3.7 0l20.1 20.8V4.6C44.4 3.2 45.6 2 47 2h6c1.4 0 2.6 1.2 2.6 2.6v53.3l20.1-20.8c1-1 2.7-1 3.7 0l4.2 4.2c.9.9.9 2.6-.1 3.6zm5.1 42H11.4c-1.4 0-2.6 1.2-2.6 2.6v6c0 1.4 1.2 2.6 2.6 2.6h77.1c1.4 0 2.6-1.2 2.6-2.6v-6c0-1.5-1.1-2.6-2.5-2.6z'/%3e%3c/svg%3e");
$icon-facebook-square-brands-metallic-orange: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%23ea6a0f' d='M87.7 2H12.3C6.6 2 2 6.6 2 12.3v75.4C2 93.4 6.6 98 12.3 98h29.4V65.4H28.2V50h13.5V38.3c0-13.3 7.9-20.7 20.1-20.7 5.8 0 11.9 1 11.9 1v13.1H67c-6.6 0-8.7 4.1-8.7 8.3v10H73l-2.4 15.4H58.3V98h29.4C93.4 98 98 93.4 98 87.7V12.3C98 6.6 93.4 2 87.7 2z'/%3e%3c/svg%3e");
$icon-facebook-square-brands-white: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%23fff' d='M87.7 2H12.3C6.6 2 2 6.6 2 12.3v75.4C2 93.4 6.6 98 12.3 98h29.4V65.4H28.2V50h13.5V38.3c0-13.3 7.9-20.7 20.1-20.7 5.8 0 11.9 1 11.9 1v13.1H67c-6.6 0-8.7 4.1-8.7 8.3v10H73l-2.4 15.4H58.3V98h29.4C93.4 98 98 93.4 98 87.7V12.3C98 6.6 93.4 2 87.7 2z'/%3e%3c/svg%3e");
$icon-linkedin-brands-metallic-orange: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%23ea6a0f' d='M91.1 2H8.8C5.1 2 2 5.1 2 8.9v82.2C2 94.9 5.1 98 8.8 98h82.3c3.8 0 6.9-3.1 6.9-6.9V8.9C98 5.1 94.9 2 91.1 2zM31 84.3H16.8V38.5H31v45.8zm-7.1-52.1c-4.6 0-8.2-3.7-8.2-8.3s3.7-8.2 8.2-8.2c4.5 0 8.2 3.7 8.2 8.2 0 4.6-3.6 8.3-8.2 8.3zm60.4 52.1H70.1V62c0-5.3-.1-12.1-7.4-12.1-7.4 0-8.5 5.8-8.5 11.8v22.7H40V38.5h13.7v6.3h.2c1.9-3.6 6.6-7.4 13.5-7.4 14.4 0 17.1 9.5 17.1 21.8v25.1z'/%3e%3c/svg%3e");
$icon-linkedin-brands-white: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%23fff' d='M91.1 2H8.8C5.1 2 2 5.1 2 8.9v82.2C2 94.9 5.1 98 8.8 98h82.3c3.8 0 6.9-3.1 6.9-6.9V8.9C98 5.1 94.9 2 91.1 2zM31 84.3H16.8V38.5H31v45.8zm-7.1-52.1c-4.6 0-8.2-3.7-8.2-8.3s3.7-8.2 8.2-8.2c4.5 0 8.2 3.7 8.2 8.2 0 4.6-3.6 8.3-8.2 8.3zm60.4 52.1H70.1V62c0-5.3-.1-12.1-7.4-12.1-7.4 0-8.5 5.8-8.5 11.8v22.7H40V38.5h13.7v6.3h.2c1.9-3.6 6.6-7.4 13.5-7.4 14.4 0 17.1 9.5 17.1 21.8v25.1z'/%3e%3c/svg%3e");
$icon-search-light-catalina-blue: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%230e256a' d='M97.3 92.3L73.2 68.1c-.4-.4-1-.7-1.6-.7h-1.9C76.1 60.5 80 51.2 80 41 80 19.5 62.5 2 41 2S2 19.5 2 41s17.5 39 39 39c10.2 0 19.5-3.9 26.5-10.3v1.9c0 .6.2 1.2.7 1.6l24.2 24.2c.9.9 2.3.9 3.2 0l1.9-1.9c.7-.9.7-2.3-.2-3.2zM41 74C22.8 74 8 59.2 8 41S22.8 8 41 8s33 14.8 33 33-14.8 33-33 33z'/%3e%3c/svg%3e");
$icon-search-light-metallic-orange: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%23ea6a0f' d='M97.3 92.3L73.2 68.1c-.4-.4-1-.7-1.6-.7h-1.9C76.1 60.5 80 51.2 80 41 80 19.5 62.5 2 41 2S2 19.5 2 41s17.5 39 39 39c10.2 0 19.5-3.9 26.5-10.3v1.9c0 .6.2 1.2.7 1.6l24.2 24.2c.9.9 2.3.9 3.2 0l1.9-1.9c.7-.9.7-2.3-.2-3.2zM41 74C22.8 74 8 59.2 8 41S22.8 8 41 8s33 14.8 33 33-14.8 33-33 33z'/%3e%3c/svg%3e");
$icon-slash: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' xml:space='preserve'%3e%3cpath fill='%231e4196' d='M25.2 97.8 21 95.4c-.8-.4-1-1.4-.6-2.2L72.7 2.8c.4-.8 1.4-1 2.2-.6L79 4.6c.7.4 1 1.4.6 2.2L27.3 97.2c-.4.8-1.4 1-2.1.6z'/%3e%3c/svg%3e");
$icon-text-clear-wenge: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%23655B58' d='M68.3 66.7l-1.6 1.6c-.9.9-2.4.9-3.3 0L50 54.9 36.6 68.3c-.9.9-2.4.9-3.3 0l-1.6-1.6c-.9-.9-.9-2.4 0-3.3L45.1 50 31.7 36.6c-.9-.9-.9-2.4 0-3.3l1.6-1.6c.9-.9 2.4-.9 3.3 0L50 45.1l13.4-13.4c.9-.9 2.4-.9 3.3 0l1.6 1.6c.9.9.9 2.4 0 3.3L54.9 50l13.4 13.4c.9.9.9 2.4 0 3.3zm15.6 17.2c-18.7 18.7-49.1 18.7-67.9 0S-2.7 34.8 16 16s49.1-18.7 67.9 0 18.8 49.2 0 67.9zm-4.3-4.3c16.3-16.3 16.3-42.8 0-59.1s-42.8-16.3-59.1 0-16.3 42.8 0 59.1c16.3 16.3 42.7 16.3 59.1 0z'/%3e%3c/svg%3e");
$icon-user-hard-hat-light: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='%23ea6a0f' d='M66.8 68c-5.4 0-8 3-16.8 3s-11.4-3-16.8-3C19.3 68 8 79.3 8 93.2c0 2.7 2.1 4.8 4.8 4.8h74.4c2.7 0 4.8-2.1 4.8-4.8C92 79.3 80.7 68 66.8 68zM14 92c.6-10.1 9-18 19.2-18 3.7 0 7.3 3 16.8 3 9.6 0 13.1-3 16.8-3 10.1 0 18.5 7.9 19.2 18H14zm10.5-60h2.4c-.6 2-.8 4-.9 6 0 13.3 10.7 24 24 24s24-10.7 24-24c0-2-.3-4-.9-6h2.4c.8 0 1.5-.7 1.5-1.5v-3c0-.8-.7-1.5-1.5-1.5H74c0-9.7-6.6-17.7-15.4-20.2C57.7 3.5 55.5 2 53 2h-6c-2.5 0-4.7 1.5-5.6 3.8C32.6 8.3 26 16.3 26 26h-1.5c-.8 0-1.5.7-1.5 1.5v3c0 .8.7 1.5 1.5 1.5zM59 12.3c5.5 2.4 9 7.8 9 13.7h-9V12.3zM47 8h6v18h-6V8zM32 26c0-5.9 3.5-11.3 9-13.7V26h-9zm1.1 6h33.8c.7 1.9 1.1 3.9 1.1 6 0 9.9-8.1 18-18 18s-18-8.1-18-18c0-2.1.4-4.1 1.1-6z'/%3e%3c/svg%3e");
$icon-warning: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3e%3cpath fill='red' d='M50 8.2c23.1 0 41.8 18.7 41.8 41.8S73.1 91.8 50 91.8 8.2 73.1 8.2 50C8.2 26.9 26.9 8.2 50 8.2M50 2C23.5 2 2 23.5 2 50s21.5 48 48 48 48-21.5 48-48S76.5 2 50 2zm-2.2 23.2h4.4c1.3 0 2.3 1 2.3 2.3v.1l-1.4 32.5c-.1 1.2-1.1 2.2-2.3 2.2h-1.7c-1.2 0-2.3-1-2.3-2.2l-1.4-32.5c0-1.3 1-2.3 2.4-2.4-.1 0 0 0 0 0zM50 66.3c-3 0-5.4 2.4-5.4 5.4s2.4 5.4 5.4 5.4 5.4-2.4 5.4-5.4c0-3-2.4-5.4-5.4-5.4z'/%3e%3c/svg%3e");
$icon-tick: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath fill='%234ad160' d='M50 2c26.5 0 48 21.5 48 48S76.5 98 50 98 2 76.5 2 50 23.5 2 50 2z'%3E%3C/path%3E%3Cpath fill='white' d='M75.7 31.3c-1-1-2.7-1-3.7 0L40.2 63.1 28 50.9c-1-1-2.7-1-3.7 0s-1 2.7 0 3.7l14.1 14.1c1 1 2.7 1 3.7 0l33.6-33.6c1.1-1.1 1.1-2.7 0-3.8z'%3E%3C/path%3E%3C/svg%3E");
