.slice {
    padding: rem-calc(66 0);

    .container {
        max-width: rem-calc(1280);
    }

    &.dark-bg {
        background-image: linear-gradient(
                        to right,
                        $green-blue 0%,
                        $dark-cornflower-blue 100%
        );
    }

    &.light-bg {
        background-color: $alice-blue;
    }

    &.mid-bg {
        background-color: $fresh-air;
        color: $catalina-blue;

        h2,
        h3,
        p {
            color: $catalina-blue;
        }
    }

    .intro {
        @include vertical-rhythm(20, 30);
        margin-bottom: rem-calc(48);
    }

    &.homepage-search {
        padding: 0;
        height: rem-calc(132);

        .grid {
            grid-template-rows: rem-calc(36 48 48);

            .bg {
                grid-row: 1/3;
                grid-column: 1/-1;
                background-color: $alice-blue;
            }

            .container {
                grid-row: 2/-1;
                grid-column: 1/-1;
            }
        }
    }

    &.homepage-banner {
        .container {
            display: grid;
            grid-template-columns: rem-calc(500 26) 1fr;
            @include breakpoint(large) {
                grid-template-columns: rem-calc(744 26) 1fr;
            }

            > div {
                grid-row: 1 / 2;
            }
        }

        .content {
            grid-column: 1 / 3;
        }

        .image {
            grid-column: 2 / -1;

            img {
                width: 100%;
            }
        }

        h1 {
            margin-bottom: rem-calc(42);
        }

        .summary {
            margin-bottom: rem-calc(48);
            padding-right: rem-calc(88);
        }
    }

    &.find-rates {
        .grid {
            grid-template-columns: repeat(2, 1fr);
            gap: rem-calc(42);
            @include breakpoint(1100) {
                column-gap: rem-calc(96);
            }
        }

        .welcome-page & {
            overflow: hidden;

            .container {
                display: grid;
                position: relative;

                > div {
                    width: 100%;
                    height: 100%;
                }
            }

            .bg-images {
                z-index: 1;

                > div {
                    position: absolute;
                }

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .non-bg-images {
                z-index: 2;
            }

            .sprite-01-svg {
                width: rem-calc(424);
                height: rem-calc(810);
                top: 33%;
                left: rem-calc(-376);
            }

            .sprite-02-svg {
                width: rem-calc(100);
                height: rem-calc(100);
                top: rem-calc(10);
                left: rem-calc(-292);
            }

            .sprite-03-svg {
                width: rem-calc(598);
                height: rem-calc(256);
                right: rem-calc(-352);
                top: 50%;
                transform: translateY(rem-calc(-88));
            }

            .sprite-04-svg {
                width: rem-calc(140);
                height: rem-calc(140);
                right: rem-calc(-194);
                bottom: rem-calc(22);
            }
        }
    }

    &.browse-info {
        .grid {
            grid-template-columns: 1fr repeat(2, rem-calc(306));
            gap: rem-calc(24);
            @include breakpoint(1080) {
                grid-template-columns: rem-calc(348) 1fr rem-calc(306) 1fr rem-calc(306);
                gap: 0;
                .tools {
                    grid-column: 3/4;
                }
                .base {
                    grid-column: 5/6;
                }
            }
        }

        .text {
            h3 > span {
                display: inline-block;
                white-space: nowrap;
                padding-bottom: rem-calc(18);
                border-bottom-width: rem-calc(3);
                border-bottom-style: solid;
            }

            &.tools {
                h3 > span {
                    border-bottom-color: $fresh-air;
                }
            }

            &.base {
                h3 > span {
                    border-bottom-color: $tiffany-blue;
                }
            }

            li {
                &:not(:last-child) {
                    margin-bottom: rem-calc(30);
                }
            }
        }
    }

    &.data-updates {
        .date {
            @include vertical-rhythm(18, 30);
        }

        .grid {
            grid-template-columns: rem-calc(324) 1fr;
            gap: rem-calc(42);
            @include breakpoint(1150) {
                gap: 0;
                grid-template-columns: rem-calc(324) 1fr rem-calc(744);
                .details {
                    grid-column: 3/4;
                }
            }
        }
    }

    &.welcome-banner {
        $bp1: 1456;
        $bp2: 1886;
        position: relative;
        overflow-x: hidden;
        @include breakpoint($bp2) {
            position: static;
        }

        .container {
            max-width: rem-calc($bp1);
            display: grid;
            grid-template-columns: 2fr 1fr;
            gap: rem-calc(24);
            @include breakpoint(large) {
                gap: 0;
                grid-template-columns: rem-calc(864) 1fr;
            }
            @include breakpoint($bp1) {
                grid-template-columns: 1fr rem-calc(864) 1fr rem-calc(424);
            }
            @include breakpoint($bp2) {
                position: relative;
            }

            > .image {
                align-self: start;
                @include breakpoint($bp1) {
                    grid-column: 4/5;
                }
                z-index: 2;

                img {
                    width: 100%;
                }
            }
        }

        .content {
            @include breakpoint($bp1) {
                grid-column: 2/3;
            }
            z-index: 2;
        }

        h1 {
            color: $alice-blue;
            margin-bottom: rem-calc(42);
        }

        a.tertiary {
            color: $white;
            margin-top: 1em;
            display: block;
        }

        .summary {
            max-width: rem-calc(682);
            @include vertical-rhythm(18, 30);
            color: $white;
            margin-bottom: rem-calc(48);
        }

        .bg-image {
            // .svg-icon-landing-page-v3 {
            @include logo($welcome-ratio, 644);
            // color: $fresh-air;
            opacity: 0.5;
            position: absolute;
            z-index: 1;
            bottom: rem-calc(30);
            left: rem-calc(653);

            .image {
                height: 100%;
            }

            // // @include breakpoint(xlarge) {
            // //   left: rem-calc(826);
            // // }
            @include breakpoint($bp1) {
                left: auto;
                right: rem-calc(-152);
            }
            @include breakpoint($bp2) {
                right: auto;
                left: rem-calc(870);
                bottom: rem-calc(-36);
            }
        }
    }

    &.about-costbuilder,
    &.how-it-works,
    &.pricing-plans,
    &.try-it {
        .summary {
            margin-bottom: rem-calc(48);

            p {
                @include vertical-rhythm(20, 30);
            }
        }
    }

    &.about-costbuilder {
        .grid {
            gap: rem-calc(126);

            > .content {
                flex: 1 0;
            }

            > .image, > .video {
                flex: 0 0 rem-calc(360);
            }
        }

        .links {
            display: block;

            > a:not(:last-child) {
                margin-bottom: rem-calc(30);
            }
        }
    }

    &.how-it-works {
        .grid {
            gap: rem-calc(120);

            > .content {
                flex: 1 0;
            }

            > .image {
                flex: 0 0 rem-calc(360);
            }
        }

        .links > a:not(:last-child) {
            margin-bottom: rem-calc(30);
        }
    }

    &.pricing-plans {
        .grid {
            display: flex;
            gap: rem-calc(15);
            @include breakpoint(small only) {
                flex-direction: column;
            }
        }
    }

    &.try-it {
        .box {
            background-color: $non-photo-blue;
            padding: rem-calc(60 120);
            text-align: center;
        }

        .links {
            justify-content: center;
        }
    }
}
