.filter-list {
    margin-bottom: rem-calc(42);
    border-bottom: $catalina-blue rem-calc(2) solid;
    display: flex;
    flex-direction: row;

    .filter-title {
        width: rem-calc(238);
        height: rem-calc(72);
        margin-bottom: rem-calc(24);
        border-left: rem-calc(2) $beau-blue solid;
        background-color: $anti-flash-white;

        &:first-child {
            border-left: none;
        }

        &.is-active {
            background-color: $catalina-blue;
            border-left: none;

            &::after {
                content: "";
                display: block;
                height: rem-calc(6);
                background: $metallic-orange;
                margin-top: rem-calc(22);
            }

            a, span {
                color: $white;

                &:hover,
                &:active {
                    text-decoration: none;
                }
            }
        }

        a, span {
            width: 100%;
            height: 100%;
            display: grid;
            place-content: center;
            font-weight: bold;
            @include vertical-rhythm(20, 30);
            text-decoration: none;
            color: $catalina-blue;

            &:hover,
            &:active {
                text-decoration: underline;
            }
        }

        &.disabled {
            a, span {
                &:hover,
                &:active {
                    text-decoration: none;
                }
            }
        }
    }
}
