.box {
  &.section-box,
  &.pricing-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.user-search,
.login-register {
  display: flex;
  align-items: center;
  height: rem-calc(30);
  transform: scale(1);
  transform-origin: right center;
  > div {
    display: flex;
    align-items: center;
    height: 100%;
  }
  a {
    @include vertical-rhythm(20, 30);
    color: $dark-cornflower-blue;
    text-decoration: none;
  }
}
.user-search .search,
.login-register .register {
  border-left: rem-calc(1) solid $catalina-blue;
  padding-left: rem-calc(20);
  margin-left: rem-calc(21);
}
.user-search {
  a.user-link {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &::before {
      content: $icon-user-hard-hat-light;
      height: rem-calc(24);
      width: rem-calc(24);
      margin-right: rem-calc(18);
    }
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  a.search-link {
    height: rem-calc(24);
    width: rem-calc(24);
    &::before {
      content: $icon-search-light-metallic-orange;
    }
  }
}
.section-box {
  $icon-h: 78;
  background-color: $white;
  box-shadow: $black-box-shadow;
  border-top-width: rem-calc(18);
  border-top-style: solid;
  padding: rem-calc(36 84 54);
  &.section-01 {
    border-top-color: $catalina-blue;
  }
  &.section-02 {
    border-top-color: $dark-cornflower-blue;
  }
  &.section-03 {
    border-top-color: $green-blue;
  }
  &.section-04 {
    border-top-color: $fresh-air;
  }
  .image {
    height: rem-calc(78);
    margin-bottom: rem-calc(24);
  }
  h3 {
    margin-bottom: 0;
    max-width: rem-calc(300);
  }
  .bottom {
    padding-top: rem-calc(42);
    position: relative;
    &::before {
      content: '';
      width: rem-calc(148);
      height: rem-calc(2);
      background-color: $catalina-blue;
      position: absolute;
      left: 0;
      top: rem-calc(20);
    }
  }
  .text {
    @include vertical-rhythm(18, 30);
  }
  .link {
    margin-top: rem-calc(30);
  }
}
.search-result-block {
  &:not(:last-child) {
    margin-bottom: rem-calc(42);
  }
  .title {
    @include vertical-rhythm(24, 20);
    margin-bottom: rem-calc(12);
    a {
      text-decoration: none;
    }
  }
  .type {
    @include vertical-rhythm(18, 24);
    margin-bottom: rem-calc(18);
    font-weight: bold;
    &.rates {
      color: $metallic-orange;
    }
    &.articles {
      color: $tiffany-blue;
    }
  }
  .taxonomy {
    @include vertical-rhythm(20, 30);
    margin-bottom: rem-calc(18);
    span {
      display: inline-block;
      &:not(:first-child) {
        position: relative;
        padding-left: rem-calc(12);
        &::before {
          content: '';
          height: rem-calc(20);
          width: rem-calc(2);
          background-color: currentColor;
          position: absolute;
          left: rem-calc(4);
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
.pricing-box {
  padding: rem-calc(30 42);
  background-color: $alice-blue;
  text-align: center;
  transition: all $fast $easing;
  h3 {
    @include vertical-rhythm(36, 48);
    padding-bottom: rem-calc(17);
    margin-bottom: rem-calc(17);
    border-bottom: rem-calc(2) solid $catalina-blue;
    color: $body-text;
    transition: all $fast $easing;
  }
  .description {
    @include vertical-rhythm(18, 30);
    margin-bottom: rem-calc(12);
    transition: all $fast $easing;
  }
  .price {
    @include vertical-rhythm(24, 30);
    font-weight: bold;
    margin-bottom: rem-calc(12);
    transition: all $fast $easing;
  }
  a {
    &:hover,
    &:active {
      background-color: $btn-bg;
      color: $btn-fg;
    }
  }
  &:hover {
    background-color: $fresh-air;
  }
  &.active {
    background-color: $green-blue;
    box-shadow: $black-box-shadow;
    color: $body-text;
    h3 {
      color: $white;
      border-bottom-color: $white;
    }
    .description,
    .price {
      color: $white;
    }
  }
}
.benefits-block {
  margin-top: rem-calc(30);
  .grid {
    display: flex;
    gap: rem-calc(84);
    .col-01 {
      flex: 0 0 rem-calc(392);
    }
    .col-02 {
      flex: 1 1 auto;
    }
    .links {
      margin-top: rem-calc(48);
    }
  }
}
.details-box {
    background: $alice-blue;
    padding: 1rem 1.5rem;

    > div + div {
        border-top: $green-blue solid 1px;
    }

    > div {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 1rem 0;

        &:first-child {
            margin-top: -1rem;
        }
        &:last-child {
            margin-bottom: -1rem;
        }

        > span {
            flex: 1 1 50%;
            color: $wenge;
            max-width: 50%;
        }

        > span:first-child {
            color: $rich-black;
        }

        &.pricing-breakdown {
            flex-wrap: wrap;

            > span:nth-child(2n+3) {
                padding-left: 1rem;
            }

            > span:nth-child(2n+4) {
                text-align: right;
                flex: 0;
                flex-basis: max-content;
            }
        }
    }
}
